import { Component, Input } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';

@Component({
  selector: 'app-toastr-holder',
  templateUrl: './toastr-holder.component.html',
  styleUrl: './toastr-holder.component.scss'
})
export class ToastrHolderComponent {

  public toastrs: Toastr[] = [];
  public id:number = 1;
  private toastrSubscription: Subscription;

  constructor(public toastrService: ToastrService){}

  ngOnInit(){
    this.toastrSubscription = this.toastrService.toastrStream().subscribe(
      t => {
        this.id++;
        t.TempID = this.id;
        this.toastrs.push(t);
        setTimeout(()=>this.removeToastr(t),t.Duration)
      }
    ); 
  }

  removeToastr(toastr:Toastr){
    var index:number = this.toastrs.findIndex(e=>e.TempID == toastr.TempID);
    this.toastrs.splice(index,1);
  }
  showToastrs(){
    //return this.toastrs.length>0;
    return true;
  }

}
