<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
    	
    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>

    
    <h1 class="p-4" *ngIf="!isGroup">User Permissions</h1>
    <h1 class="p-4" *ngIf="isGroup">Group Permissions</h1>
    <h3 class="text-red-600" *ngIf="unsavedChanges">You currently have unsaved changes, remember to save!</h3>
    <table>
        <thead>
            <tr>
                <th class="pr-2">User</th>
                <th class="pr-2">Location</th>
                <th class="pr-2">View Audits</th>
                <th class="pr-2">Save Audits</th>
                <th class="pr-2">Edit Audits</th>
                <th class="pr-2">Edit Locations</th>
                <th class="pr-2">Edit Users</th>
                <th class="pr-2">Schedule Audits</th>
                <th class="pr-2">Delete Scheduled Audits</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of tableData"
                [ngClass]="{'border-solid border-t-2 py-4 border-t-offblack':a.Location.LocationLevelID == -1}">

                <td class="pr-4" [ngClass]="{'font-bold':a.User.UserTypeID == 2}"><span
                        *ngIf="a.Location.LocationLevelID == -1">{{a.User.DisplayName}}</span></td>
                <td class="pr-4"
                    [ngClass]="{'text-grey-900':!a.Location.HasPermission || !a.Location.Active,'pl-2':(a.Location.LocationLevelID - minLevel) == 1,'pl-4':(a.Location.LocationLevelID - minLevel) == 2,'pl-6':(a.Location.LocationLevelID - minLevel) == 3,'pl-8':(a.Location.LocationLevelID - minLevel) >= 4,'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    {{a.Location.LocationName}} <span *ngIf="!a.Location.Active" class="text-red"> (Inactive)</span></td>
                <td class="pr-4  content-center"
                    [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    <div class="flex justify-center ">
                        <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                            *ngIf="a.Location.HasPermission"
                            [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.ViewAudit && !a.Location.Features.ViewAudit,' cursor-not-allowed':!a.Location.HasPermission}"
                            [checked]="a.Location.Features.ViewAudit"
                            (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,1,$event)" />
                    </div>

                </td>
                <td class="pr-4"
                    [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    <div class="flex justify-center ">
                        <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                            *ngIf="a.Location.HasPermission"
                            [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.SaveAudit && !a.Location.Features.SaveAudit,'cursor-not-allowed':!a.Location.HasPermission}"
                            [checked]="a.Location.Features.SaveAudit"
                            (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,2,$event)" />
                    </div>

                </td>
                <td class="pr-4"
                    [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    <div class="flex justify-center ">
                        <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                            *ngIf="a.Location.HasPermission"
                            [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.EditAudit && !a.Location.Features.EditAudit,'cursor-not-allowed':!a.Location.HasPermission}"
                            [checked]="a.Location.Features.EditAudit"
                            (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,3,$event)" />
                    </div>

                </td>
                <td class="pr-4"
                    [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    <div class="flex justify-center ">
                        <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                            *ngIf="a.Location.HasPermission"
                            [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.EditLocations && !a.Location.Features.EditLocations,' cursor-not-allowed':!a.Location.HasPermission}"
                            [checked]="a.Location.Features.EditLocations"
                            (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,4,$event)" />
                    </div>

                </td>
                <td class="pr-4"
                    [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    <div class="flex justify-center ">
                        <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                            *ngIf="a.Location.HasPermission"
                            [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.EditUsers && !a.Location.Features.EditUsers,' cursor-not-allowed':!a.Location.HasPermission}"
                            [checked]="a.Location.Features.EditUsers"
                            (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,5,$event)" />
                    </div>

                </td>
                <td [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    <div class="flex justify-center ">
                        <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                            *ngIf="a.Location.HasPermission"
                            [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.ScheduleAudit && !a.Location.Features.ScheduleAudit,' cursor-not-allowed':!a.Location.HasPermission}"
                            [checked]="a.Location.Features.ScheduleAudit"
                            (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,6,$event)" />
                    </div>

                </td>
                <td [ngClass]="{'border-solid border-b-[1px] border-b-green-300':a.Location.LocationLevelID == -1}">
                    <div class="flex justify-center ">
                        <input type="checkbox" [disabled]="!a.Location.HasPermission || saveStarted"
                            *ngIf="a.Location.HasPermission"
                            [ngClass]="{'bg-grey-900':a.Location.SubLocationFeatures.DeleteScheduledAudit && !a.Location.Features.DeleteScheduledAudit,' cursor-not-allowed':!a.Location.HasPermission}"
                            [checked]="a.Location.Features.DeleteScheduledAudit"
                            (change)="updateSelection(a.User.UserID,a.Location.LocationLevelID,a.Location.LocationID,10,$event)" />
                    </div>

                </td>
            </tr>
        </tbody>
    </table>
    <div>
        <button class="btn-primary" (click)="startSave()" loading="">Save</button>
    </div>

</div>