import { BasicUser } from "../basic-user";

export class SaveUser extends BasicUser{
    Email:string
    Username:string;
    HasLoggedIn:boolean;
    Disabled:boolean = false;
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
    }
}