<div *ngFor="let a of actionList">
  <div 
    class="border-solid border-2 my-1 border-grey-300 rounded-md bg-standardwhite flex-col justify-between p-1 hover:cursor-pointer hover:border-green-300 w-full"
    [ngClass]="{ 'border-yellow-300': editing && a.Editing}" (click)="editAction(a)">
    <div class="flex justify-between">
      <strong class="w-3/4">{{currentText(a)}}</strong>
      <p *ngIf="editing && a.Editing">editing</p>
    </div>
    <div class="mt-2 flex-col">
      <div class="flex">
        <p class="mr-1">Assigned to:</p>
        <strong>{{a.ActionOwner.DisplayName}}</strong>
      </div>
      <div class="flex">
        <p class="mr-1">Completion date:</p>
        <strong>{{util.humanDate(a.ActionDeadline)}}</strong>
      </div>
    </div>
  </div>
</div>
<div *ngIf="actionList.length == 0" class="ml-3">
  <p class="text-grey-900">No actions set.</p>
</div>