<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
	
    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>
	

    <h1 *ngIf="!isGroup" class="p-4">User Audits</h1>
    <h1 *ngIf="isGroup" class="p-4">Group Audits</h1>
    <div class="mt-4 mx-4">
        <p *ngIf="!isGroup" class="text-2xl font-bold">Select User</p>
        <p class="text-2xl font-bold" *ngIf="isGroup">Select Group</p>
        <select class="mt-2 mr-4 text-box xl:w-2/5" [(ngModel)]="selectedUser" (change)="loadUser()">
            <option [disabled]="u.UserID == -1" *ngFor="let u of users" [ngValue]="u">{{u.DisplayName}}</option>
        </select>
    </div>
    <div *ngIf="selectedUser.UserID != -1" class="lg:mx-6 mx-4 mt-6">
        <h3 class="text-red-600" *ngIf="unsavedChanges">You currently have unsaved changes, remember to save!</h3>
        <table>
            <tbody>
                <tr class="border-b-offblack border-b-2 mb-2 py-1">
                    <td class="font-bold">All</td>

                    <td class="flex justify-center py-2 pl-3">
                        <app-toggle-switch [(checked)]="allPermissions" (checkedChange)="updateAllPermissions()"></app-toggle-switch>
                    </td>
                </tr>

                <tr *ngFor="let p of selectedUser.AuditPermissions">
                    <td>{{p.AuditName}}</td>
                    <td class="flex justify-center py-2 pl-3">
                        <app-toggle-switch [(checked)]="p.HasPermission" (checkedChange)="loadUser()"></app-toggle-switch>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-3">
            <button class="btn-primary mr-4" (click)="saveChanges()">Save</button>
            <button class="btn-warning" (click)="resetPage()">Undo</button>
        </div>
    </div>
</div>