<div class="lg:px-5 px-2 my-2">
    <div *ngIf="question.QuestionTypeID!=6" class="border-b-[1px] border-solid border-grey-500 ">
        <div *ngIf="question.QuestionTypeID !=8" class="flex justify-between py-2 lg:flex-row flex-col">
            <div *ngIf="!isCompliant()" class="bg-red-500 rounded-full text-standardwhite px-4 lg:hidden mb-3 text-center">
                <span class="">Not Compliant</span>
            </div>
            <div class=" lg:w-3/5 px-2 w-full">
                <div class="flex">
                    <p class="text-green-700 mr-2 font-bold">Q</p>
                    <div>
                        <p class="font-bold">{{question.QuestionHeader}}</p>
                        <p class="">{{question.QuestionBody}}</p>
                    </div>
                </div>
                <div class="flex mt-2">
                    <p class="text-green-400 mr-2 font-bold">A</p>
                    <p>{{question.QuestionResult}}</p>
                </div>
                <div class="mx-5 mt-3 p-2 border-solid border-[1px] border-grey-500 rounded-md bg-grey-100"
                    *ngIf="question.QuestionComments.length>0">
                    <p class="text-green-700">Comments</p>
                    <p class="italic">{{question.QuestionComments}}</p>
                </div>
            </div>
            <div class="flex flex-col "
                [ngClass]="{'justify-between':question.MaxScore>0,'justify-end':!isCompliant()}">
                <div *ngIf="!isCompliant()" class="bg-red-500 rounded-full text-standardwhite px-4 lg:block hidden">
                    <span class="text-sm">Not Compliant</span>
                </div>
                <div class="flex justify-end mt-4">
                    <div class="flex">
                        <span
                            [ngClass]="{'text-green-400 cursor-pointer':question.QuestionAttachments.length>0,'text-grey-500 cursor-not-allowed':question.QuestionAttachments.length<=0}"
                            class="material-symbols-outlined text-3xl align-bottom  self-center"
                            (click)="switchAttachmentPanel()">attach_file</span>
                        <span
                            [ngClass]="{'text-green-400 cursor-pointer':question.Photos.length>0,'text-grey-500 cursor-not-allowed':question.Photos.length<=0}"
                            class="material-symbols-outlined text-3xl align-bottom  self-center ml-2"
                            (click)="switchPhotoPanel()">photo_camera</span>
                        <span
                            [ngClass]="{'text-red-600 cursor-pointer':question.Actions.length>0,'text-grey-500 cursor-not-allowed':question.Actions.length<=0}"
                            class="material-symbols-outlined text-3xl align-bottom  self-center ml-2"
                            (click)="switchActionPanel()">report</span>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="question.QuestionTypeID == 8" class="px-2">
            <div class="flex">
                <p class="text-green-700 mr-2">Q</p>
                <div>
                    <p>{{question.QuestionHeader}}</p>
                </div>
            </div>
            <div *ngIf=" question.QuestionResult.length>0">
                <img [src]="question.QuestionResult" class="max-h-36" />
            </div>
            <div *ngIf=" question.QuestionResult.length==0">
                <p>No signature provided.</p>
            </div>
        </div>
        <div *ngIf="expandedPhotoPanel" class="px-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md">
            <h4 class="mb-4 mt-2 font-semibold text-green-700">Photos</h4>
            <div class="flex flex-wrap">
<div *ngFor="let p of question.Photos" class=" ml-2 mb-2 ">
                <img [src]="photoSrc(p)" class="max-h-52"/>
            </div>
            </div>
            
        </div>
        <div *ngIf="expandedAttachmentPanel" class="p-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md">
            <h4 class="mb-4 font-semibold text-green-700">Attachments</h4>

            <div *ngFor="let q of question.QuestionAttachments" class="mt-1">
                <p class="underline hover:text-green-700 cursor-pointer" (click)="downloadAttachment(q.GUID)">{{filenameReduction(q.FileName)}}</p>
            </div>

        </div>
        <div *ngIf="expandedActionPanel" class="px-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md ">
            <h4 class="mb-4 mt-2 font-semibold text-green-700">Actions</h4>
            <div *ngFor="let a of question.Actions" class="flex mb-3">
                <div class="flex w-full">
                    <strong class="flex-grow">{{a.ActionDescription}}</strong>
                    <p class="w-1/5" [ngClass]="{'text-red-600':isPastDate(a.ActionDeadline)}">
                        {{humanDate(a.ActionDeadline)}}</p>
                    <div class="flex justify-end w-1/6">
                        <p class="underline hover:cursor-pointer hover:text-green-700" (click)="detailAction(a.ActionID)">View Action</p>
                    </div>

                </div>


            </div>
        </div>
    </div>
    <div *ngIf="question.QuestionTypeID==6" class="border-t-4 mt-2 pt-2 border-solid border-grey-500">
        <strong>{{question.QuestionHeader}}</strong>
    </div>

</div>