import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditCompletionInfo } from '../models/Audits/audit-completion-info';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import { AuditSummaryResultRequest } from '../models/Audits/AuditResults/audit-summary-result-request';
import { Audit } from '../models/Audits/audit';
import { AuditResultDetail } from '../models/Audits/AuditResults/audit-result-detail';

@Injectable({
  providedIn: 'root'
})
export class AuditResultsService {

  constructor(private httpClient: HttpClient) {

  }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: "response" as "response",
    responseType: "json" as const,
    withCredentials: true
  };

  
  getAuditResultList(request:AuditSummaryResultRequest): Observable<Audit[]> {
    return this.httpClient.post<Audit[]>(`${environment.apiUrl}auditresults/auditresultlist`,request, this.httpOptions).pipe(
      map(res => {
        if(res.body!=null){
          var returnList:Audit[] = [];
          res.body.forEach(e=>{
            returnList.push(new Audit(e));
          })
          return returnList;
        }
        return [];
      })
    );
  }
  getAuditResultDetail(request:number):Observable<AuditResultDetail>{
    return this.httpClient.post<AuditResultDetail>(`${environment.apiUrl}auditresults/auditresult`,request, this.httpOptions).pipe(
      map(res => {
        return new AuditResultDetail(res.body!)        
      })
    );
  }


}
