import { Component } from '@angular/core';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})
export class SecureComponent {

  public menuIsOpen: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  menuOpen(){
    this.menuIsOpen = true;
  }

  menuClosed(){
    this.menuIsOpen = false;
  }
}
