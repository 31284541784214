<div class="primary-green-border rounded-lg p-4 mb-4 lg:mx-4"  [ngClass]="{'border-red-600':auditResultDetail.TotalOverdueActions>0,'border-yellow-300':auditResultDetail.TotalOutstandingActions<=0 && auditResultDetail.TotalOutstandingActions>0}">
    <div *ngIf="auditResultDetail.AuditID == -1">
        <h2>Loading...</h2>
    </div>
    <div *ngIf="auditResultDetail.AuditID != -1">
        <div class="flex justify-between">
            <div>
                <h2>{{auditResultDetail.AuditName}} <h4><span
                            class="text-green-300 h5span">&#64;</span>{{auditResultDetail.Location.LocationName}}</h4>
                </h2>
            </div>
            <div>
                <h2>{{calcPercent()}}%</h2>
            </div>
        </div>
        <div class="mt-4 mx-2  justify-between hidden lg:flex">            
            <h4>Completed: {{humanDate(auditResultDetail.SubmittedDate)}} <span class="text-green-300 font-bold h4span">by</span> {{auditResultDetail.UserCreatedName}}</h4>
            <h4 class="">Outstanding Actions: <span class="h4span">{{auditResultDetail.TotalOutstandingActions}}</span> <span class="h4span" *ngIf="overdueActions()>0"> <strong class="text-red-600 ml-2">({{overdueActions()}} overdue)</strong> </span></h4>
        </div>
        <div class="mt-4 mx-2  flex flex-col lg:hidden">  
            <h4>Completed: {{humanDate(auditResultDetail.SubmittedDate)}}</h4>
            <h4> <span class="text-green-300 font-bold h4span">by</span> {{auditResultDetail.UserCreatedName}}</h4>
            <h4 class="">Outstanding Actions: <span class="h4span">{{auditResultDetail.TotalOutstandingActions}}</span> <span class="h4span" *ngIf="overdueActions()>0"> <strong class="text-red-600 ml-2">({{overdueActions()}} overdue)</strong> </span></h4>
      
        </div>
        <h3 class="my-5">Questions</h3>
        <div *ngFor="let q of auditResultDetail.Questions"> 
           
            <app-audit-result-detail-question [question]="q"></app-audit-result-detail-question>
        </div>
    </div>
</div>