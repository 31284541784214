import { BasicUser } from "../basic-user";

export class UserGroup extends BasicUser{
    Users:BasicUser[];
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
        if (values.hasOwnProperty('Users')) {
            this.Users = [];
            ((values as UserGroup)['Users'] as BasicUser[]).forEach(element => {
                this.Users.push(new BasicUser(element));
            });
        }
    }
}