import { LocationResult } from "../Locations/location-result";
import { storageReference } from "./storage-reference";
import { UserSettings } from "../../interfaces/user-settings";
import { UserLocationSave } from "../user-location-save";

export const LocalStorageReferences = [
    new storageReference(1,UserSettings,'userSettings'),
    new storageReference(2,UserLocationSave,'careAudits'),
    new storageReference(3,[],'timeChecks'),
    new storageReference(4,LocationResult,'locations')  

]

/*      case 2:
        this._localStorage.removeItem('careaudits');
        this.observables._savedAudits.next(null as any);
        break;
      case 3:
        this._localStorage.removeItem('timingcheck');
        this.observables._timeChecks.next(null as any);*/