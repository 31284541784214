import { Component, EventEmitter, INJECTOR, Input, Output } from '@angular/core';
import { ComplianceAction } from 'src/app/core-module/models/Audits/compliance-action';
import { StandardQuestionEntry } from 'src/app/core-module/models/Audits/standard-question-entry';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-question-action-useraction',
  templateUrl: './question-action-useraction.component.html',
  styleUrls: ['./question-action-useraction.component.scss']
})
export class QuestionActionUseractionComponent {
  @Input() actionList: ComplianceAction[];
  @Input() editing:boolean = false;
  @Output() actionChange = new EventEmitter<ComplianceAction>()
  @Output() actionListChange = new EventEmitter<ComplianceAction[]>();
  @Output() editingAction = "";

  constructor(public util:UtilService){

  }
  editAction(CA:ComplianceAction){
    this.clearAllEdit();
    CA.Editing = true;
    this.actionChange.emit(CA);
  }
  clearAllEdit(){
    this.actionList.forEach(e=>{
      e.Editing = false;
    })
  }
  currentText(value:ComplianceAction):string{
    if(value.StandardAction.StandardQuestionEntryID==-1){
      return value.ActionNote;
    }else{
      return value.StandardAction.StandardEntryText;
    }
  }
}
