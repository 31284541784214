import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComplianceAction } from 'src/app/core-module/models/Audits/compliance-action';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { Action } from 'src/app/core-module/models/Compliance-Actions/action';

@Component({
  selector: 'app-new-action-quick',
  templateUrl: './new-action-quick.component.html',
  styleUrl: './new-action-quick.component.scss'
})
export class NewActionQuickComponent {

  public _actionUsers:BasicUser[]=[];
  @Input()
  set Users(actionUsers:BasicUser[]){
    this._actionUsers = actionUsers;
  };
  @Input() newAction:ComplianceAction = new ComplianceAction();
  @Output() newActionChange = new EventEmitter<ComplianceAction>();
  @Output() resetNewAction = new EventEmitter();
  @Output() saveNewAction = new EventEmitter<ComplianceAction>();

  //public newAction:ComplianceAction = new ComplianceAction();
  public currentUser:BasicUser = new BasicUser({ 'UserID': -1, 'DisplayName': 'Please Select...', 'Seq': 0, 'UserTypeID': 0 });
  public showWarning:boolean = false;
  
  saveAction(){
    if(this.newAction.ActionNote.length==0 || this.currentUser.UserID == -1){
      this.showWarning = true;
      return;
    }else{
      this.clearWarning();      
    }
    this.newAction.ActionOwner = this.currentUser;
    this.saveNewAction.emit();
    
  }
  resetAction(){
    this.resetNewAction.emit()
  }
  clearWarning(){
    this.showWarning = false;
  }


  userComparison(a: BasicUser, b: BasicUser): boolean {
    if (a == undefined || b == undefined || a == null || b == null) {
      return false;
    }
    return a.UserID == b.UserID && a.UserTypeID == b.UserTypeID;
  }
}