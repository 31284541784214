<h2 class="pt-12 pb-4">Audit Workflow</h2>

<div *ngFor="let q of audit.Questions let idx = index">
    <div class="p-4 border-t border-grey-500" [ngClass]="{'border-b': idx == audit.Questions.length-1}">
        <p class="font-bold">{{q.QuestionHeader}}</p>
        <div class="flex pt-2">
            <button class="btn-primary" (click)="moveUp(q)" [disabled]="idx == 0">Move Up</button>
            <button class="btn-primary ml-4" (click)="moveDown(q)" [disabled]="idx == audit.Questions.length-1">Move Down</button>
        </div>
    </div>
</div>
