import { BasicUser } from "../basic-user";
import { AuditLocation } from "../Locations/audit-location";

export class PermissionsUser extends BasicUser {
    public Locations:AuditLocation[];
    
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
        if (values.hasOwnProperty('Locations')) {
            this.Locations = [];
            ((values as PermissionsUser)['Locations'] as AuditLocation[]).forEach(element => {
                this.Locations.push(new AuditLocation(element));
            });
        }
    }
    
}