import { Injectable } from '@angular/core';
import { Toastr } from '../models/Utils/toastr';
import { Observable, Subject, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor() { }

  private subject = new Subject<Toastr>();

  addToastr(toastr:Toastr){
    this.subject.next(toastr);
  }

  toastrStream():Observable<Toastr>{
    return this.subject.asObservable();
  }

}
