<div class="flex gap-4 justify-center border border-green-300 rounded-md p-4 bg-green-50 mx-4">
    <select class="text-box w-auto" [(ngModel)]="selectedMonth" (change)="updatedDate()">
        <option *ngFor="let m of months" [ngValue]="m">{{m.Description}}</option>
    </select>
    <select class="text-box w-auto" [(ngModel)]="selectedYear" (change)="updatedDate()">
        <option *ngFor="let y of years" [ngValue]="y">{{y}}</option>
    </select>
</div>

<div class="lg:flex hidden lg:visible">
    <div>
        <div class="m-4 mb-1 flex">
            <p class="w-16 text-center text-sm font-bold">Sun</p>
            <p class="w-16 text-center text-sm font-bold">Mon</p>
            <p class="w-16 text-center text-sm font-bold">Tue</p>
            <p class="w-16 text-center text-sm font-bold">Wed</p>
            <p class="w-16 text-center text-sm font-bold">Thu</p>
            <p class="w-16 text-center text-sm font-bold">Fri</p>
            <p class="w-16 text-center text-sm font-bold">Sat</p>
        </div>
        <div class="p-4 pt-0">
            <div *ngFor="let w of weeksInMonth; index as i" class="flex">
                <div *ngFor="let d of daysInWeek; index as i2" class="">
                    <app-calendar-cell [date]="datesForCells[(i*7)+i2]" [events]="eventsForCells[(i*7)+i2]" [focusMonth]="selectedMonth.ID" (selectedDate)="selectedChanged($event)"></app-calendar-cell>            
                </div>
            </div>
        </div>
    </div>
    
    <div class="m-4 mt-8 w-1/2">
        <app-day-detail [events]="this.selectedEvents" [selectedDate]="this.selectedDate" (DeleteEvent)="deleteEvent($event)"></app-day-detail>
    </div> 
</div>

<div class="lg:hidden mt-4">
    <div *ngFor="let e of this.allEvents">
        <app-scheduled-audit [event]="e" (DeleteEvent)="deleteEvent2($event)" [showDate]="true"></app-scheduled-audit>
    </div>
</div>
