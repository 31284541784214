export class BasicUser{
    public UserID: number = -1;
    public UserTypeID: number = -1;
    public DisplayName: string = "";
    public Valid:boolean = true;
    public Selected:boolean = false;
    public DailyReminders:boolean = true;
    public WeeklySummary:boolean = true;
    public Active:boolean = true;
    constructor(values: Object = {}){
        Object.assign(this, values);   
    }
}