import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { NewUserResult } from 'src/app/core-module/models/UserManagement/new-user-result';
import { SaveUser } from 'src/app/core-module/models/UserManagement/save-user';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrl: './new-user.component.scss'
})
export class NewUserComponent {
  public isGroup: boolean = false;
  public invalidEmail: boolean = false;
  public emailUsed: boolean = false;
  public invalidUserName: boolean = false;
  public invalidDisplayName: boolean = false;
  public minUserLength: number = 6;
  public noLicense:boolean = false;
  public isUsernameInUse:boolean = false;
  public isSaving:boolean = false;

  public newUser: SaveUser = new SaveUser({ UserTypeID: 1 });

  constructor(private router:Router,private UMS: UserManagementService, private toast: ToastrService){
     if(this.router.url == '/settings/users/newgroup'){
      this.isGroup = true;
      this.newUser = new SaveUser({ UserTypeID: 2 })
    }
  }

  resetUser() {
    var userTypeID = 1;
    if(this.isGroup){userTypeID = 2}
    this.newUser = new SaveUser({UserTypeID:userTypeID});
  }
  validateUser() {
    this.resetFlags();
    var isValid = true;
    if (!this.isGroup) {
      if (this.newUser.Username.length < this.minUserLength) {
        this.invalidUserName = true;
        isValid = false;
      }
      if(this.hasWhiteSpace(this.newUser.Username)){
        this.invalidUserName = true;
        isValid = false;
      }
      if (!this.validEmail(this.newUser.Email)) {
        this.invalidEmail = true;
        isValid = false;
      }
    }
    if (this.newUser.DisplayName.length < this.minUserLength) {
      this.invalidDisplayName = true;
      isValid = false;
    }
    if(isValid){
      this.saveUser()
    }
  }
  saveUser(){
    this.isSaving = true;
    this.UMS.newUser(this.newUser).pipe(tap((a) => { this.processReturn(a)})).subscribe();
  }
  processReturn(userResult:NewUserResult){
    this.isSaving = false;
    if(userResult.IsError == false){
      //Toastr
      this.toast.addToastr(new Toastr({Type: 2, Message: "User Saved."}));
      this.resetUser();
    }else{
      if(!userResult.IsLicenseAvailable){
        this.noLicense = true;
      }
      if(userResult.IsUsernameInUse){
        this.isUsernameInUse = true;
      }
      if(!userResult.IsEmailValid){
        this.invalidEmail = true;
      }
      if(userResult.IsEmailInUse){
        this.emailUsed = true;
      }
      if(!userResult.IsUsernameValid){
        this.invalidUserName = true;
      }
    }
  }
  resetFlags() {
    this.invalidEmail = false;
    this.invalidUserName = false;
    this.invalidDisplayName = false;
    this.emailUsed = false;
    this.isUsernameInUse = false;
  }
  validEmail(email: string) {
    var reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return reg.test(email);

  }
  hasWhiteSpace(s:string) {
    return s.indexOf(' ') >= 0;
  }
  goBack(){
    this.router.navigateByUrl("/settings");
  }
}
