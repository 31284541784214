import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-trial-success',
  templateUrl: './trial-success.component.html',
  styleUrl: './trial-success.component.scss'
})
export class TrialSuccessComponent {

  public showNext: boolean = false;
  public checkoutSession: string = "";
  public stripeRef: string = "";

  constructor(public userService: UserManagementService, public route: ActivatedRoute) {}
 
  ngOnInit(){
    this.route.queryParams.subscribe(params => {
      this.stripeRef = params['ref'];
      this.checkoutSession = params['session_id']

      this.userService.finaliseTrial(this.stripeRef,this.checkoutSession).pipe(
        tap(res => {
          this.showNext = true;
        })
      ).subscribe()
    });

  }

  getStarted(){
    
  }
}
