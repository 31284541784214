import { Component, EventEmitter, Input, Output } from '@angular/core';
import { parseISO } from 'date-fns';
import { Question } from 'src/app/core-module/models/Audits/question';

@Component({
  selector: 'app-date-only',
  templateUrl: './date-only.component.html',
  styleUrls: ['./date-only.component.scss']
})
export class DateOnlyComponent {
  public selectedDate:Date = new Date();

  @Input() width:string = '1/5';

  @Input() currentQuestion:Question;
  @Output() currentQuestionChange = new EventEmitter<Question>();
  
  ngOnInit(){
    this.validateIncomingDate(this.currentQuestion.CompletionInfo.Answer);

  }

  validateIncomingDate(value:Date){
    if(value == null){
      this.selectedDate = new Date();
      this.updateCurrentDate();
    }else{
      if(typeof value == 'string'){
        value = parseISO(value);
      }
      this.selectedDate = value;
    }
  }
  updateCurrentDate(){
    this.currentQuestion.CompletionInfo.Answer = this.selectedDate;
    this.currentQuestion.CompletionInfo.QuestionAnswered = true;
    this.currentQuestionChange.emit(this.currentQuestion);
  }

  
}

