
export class QuestionAttachment{
    public FileName: string;
    public GUID: string;
    public Length: number;
    public MimeType: string;
    constructor(values: Object = {}){
        Object.assign(this, values);
    }

}