import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-audit-main-header',
  templateUrl: './audit-main-header.component.html',
  styleUrls: ['./audit-main-header.component.scss']
})
export class AuditMainHeaderComponent {
  @Input() showHeader:boolean;  
  @Input() header:string = "";
  @Input() locationName:string;
  @Input() auditName:string;
}
