export class ActionListRequest{
    public LocationID:number = -1;
    public AuditID:number = -1;
    public AuditorUserID:number = -1;
    public StartDate:Date;
    public EndDate:Date;
    public IncludeResolved:boolean = false;
    public DashboardRequest:boolean = false;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}