export class AuditCompletionInfo{
    public UserID: number = -1;
    public UserName: string = "";
    public LocationID: number = -1;
    public LocationName:string = "";
    public SubmittedDate: Date = new Date();
    public StartedDate: Date = new Date();
    public Score: number = -1;
    public MaxScore: number = -1;
    public MaximumAvailableQuestion:number = -1;
    public TotalQuestionsCompleted:number = -1;
    public AuditResultID:number = -1;
    public TotalOutstandingActions = -1;
    public TotalOverdueActions = -1;
    constructor(values: Object = {}){
        Object.assign(this, values);     
    }
}