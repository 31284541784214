<h2 class="pt-12">Audit Entry Options</h2>

<!--<app-entry-info-toggle heading="Branching" help="Are branching paths allowed to be created in the audit depending on the outcome of questions?" [(value)]="audit.AuditEntryOptions.AllowBranching" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Desktop Completion" help="Can this audit be completed on desktop or is it restricted to mobile devices only?" [(value)]="audit.AuditEntryOptions.AllowCompletionOnDesktop" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Photos" help="Are photos allowed to be taken to support the questions in this audit?" [(value)]="audit.AuditEntryOptions.AllowPhotos" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Repeats" help="Are blocks of repeated questions allowed in this audit?" [(value)]="audit.AuditEntryOptions.AllowRepeats" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Mobile Sync" help="Can this audit be synced to mobile devices?" [(value)]="audit.AuditEntryOptions.CanSync" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Exclude Email Grouping" help="???" [(value)]="audit.AuditEntryOptions.ExcludeEmailGrouping" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<app-entry-info-toggle heading="Hide on Diary" help="Should this audit be hidden on the diary?" [(value)]="audit.AuditEntryOptions.HideDiary" (valueChange)="auditChanged()"></app-entry-info-toggle>
<app-entry-info-toggle heading="Hide Score" help="Should the compliance scores for question in this audit be hidden?" [(value)]="audit.AuditEntryOptions.HideScore" (valueChange)="auditChanged()"></app-entry-info-toggle>
<!--<app-entry-info-toggle heading="NA Default Unchecked" help="???" [(value)]="audit.AuditEntryOptions.NADefaultUnchecked" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Relax Required Questions" help="Should the audits be allowed to be completed with unanswered required questions?" [(value)]="audit.AuditEntryOptions.RelaxRequiredQuestions" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Signature on Desktop" help="Should the signature box on completion of this audit be shown on desktop as well as mobile devices?" [(value)]="audit.AuditEntryOptions.ShowSignatureBoxOnDesktop" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Timestamp Photos" help="Should photos taken to support the audit answers be timestamped?" [(value)]="audit.AuditEntryOptions.TimestampPhotos" (valueChange)="auditChanged()"></app-entry-info-toggle>-->
<!--<app-entry-info-toggle heading="Use Section Scores" help="Should compliance scores for each of the sections of the audit be shown?" [(value)]="audit.AuditEntryOptions.UseSectionScores" (valueChange)="auditChanged()"></app-entry-info-toggle>-->

