import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditHolder } from 'src/app/core-module/models/Audits/audit-holder';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { UserLocationSave } from 'src/app/core-module/models/user-location-save';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { AuditValidationService } from 'src/app/core-module/services/audits/audit-validation.service';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-incomplete-panel',
  templateUrl: './incomplete-panel.component.html',
  styleUrls: ['./incomplete-panel.component.scss']
})
export class IncompletePanelComponent {
  @Input() Audits:UserLocationSave[] = [];
  @Output() Refresh  = new EventEmitter(); 
  public location:AuditLocation;
  public readyForDelete:UserLocationSave[] = [];
  constructor(public util:UtilService,public auditValidation:AuditValidationService,private router: Router,private auditService:AuditService,private authService:AuthService, private toast: ToastrService){
    
  }
  getUsername():string{
    var username:string = "";
    this.authService.getUserName()
    .pipe(
      tap((a) => {
        username = a.Username;
      })
    )
    .subscribe();
    return username;

  }
  jumpToAudit(savedAudit:FlattenedQuestionHolder,location:AuditLocation){
    this.router.navigateByUrl('audit',{state: {"SavedAudit":savedAudit, "SavedLocation":location}});
  }
  changeDeleteConfirmStatus(auditDetails:UserLocationSave){
    if(this.readyForDelete.some(e=>e.FlattenedQuestions[0].AuditID == auditDetails.FlattenedQuestions[0].AuditID && e.Location.LocationID == auditDetails.Location.LocationID)){
      var index:number = this.readyForDelete.findIndex(f=>f.FlattenedQuestions[0].AuditID ==auditDetails.FlattenedQuestions[0].AuditID && f.Location.LocationID == auditDetails.Location.LocationID)
      this.readyForDelete.splice(index,1);
    }else{
      this.readyForDelete.push(auditDetails);
    }
  }
  onDeleteList(auditDetails:UserLocationSave):boolean{
    if(this.readyForDelete.some(e=>e.FlattenedQuestions[0].AuditID == auditDetails.FlattenedQuestions[0].AuditID && e.Location.LocationID == auditDetails.Location.LocationID)){
      return true;
    }else{
      return false;
    }
  }
  removeAudit(savedAudit:FlattenedQuestionHolder,location:AuditLocation){
    var username = this.getUsername(); 
    this.auditService.removeLocalAudit(savedAudit.AuditID,location.LocationID,username);
    this.toast.addToastr(new Toastr({Type: 2, Message: "Audit removed."}));
    this.Refresh.emit();
    //this.confirmDelete = false;
  }
  readyToSubmit(FQH: FlattenedQuestionHolder): string {
    var textHolder: string[] = [];
    var nonCompliantQuestions = this.auditValidation.incompleteNonCompliant(FQH);
    if (nonCompliantQuestions.length > 0) {
      textHolder.push(nonCompliantQuestions.length + " questions that require additional information.");
    }
    var required: number = this.auditValidation.remainingQuestions(FQH, true);
    if (required > 0) {
      textHolder.push(required + " required questions to complete.");
    }
    var returnText = "";
    textHolder.forEach(e => {
      if (returnText.length > 0) {
        returnText += " "
      }
      returnText += e
    }
    )
    if(returnText.length == 0){
      returnText = "Ready to submit.";
    }
    return returnText;
  }
  /*submitAudit(FQH:FlattenedQuestionHolder, location: AuditLocation){
    var username = this.getUsername();
    if(this.auditValidation.validCompleteQuestions(FQH)){
      var SubmitInfo:AuditHolder = new AuditHolder({'Audit':FQH, 'LocationID':location.LocationID});      
      this.auditService.saveAudit(SubmitInfo).pipe(tap((a)=>{
        console.log(a);
      })).subscribe();
      
      //submit
    }else{
      console.log('submitAudit - not valid');
      //show error the audit is not valid
    }
  }*/
}
