import { CanActivateFn } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { inject } from "@angular/core";
import { EMPTY, Observable, catchError, lastValueFrom, of, tap } from "rxjs";
import { UtilService } from "../services/util.service";

export function authGuard(): CanActivateFn{
    return async () => {
        const authService: AuthService = inject(AuthService);
        const utilService: UtilService = inject(UtilService);
        
        if (utilService.isOnline){
            let onlineResult:any = await lastValueFrom(authService.isLoggedIn());
            if (onlineResult.status == 200) {return true;}
            return false;
        } else {
            return authService.isLoggedInOffline();
        }
    }
}