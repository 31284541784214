import { BehaviorSubject } from 'rxjs';
import { UserSettings } from '../../interfaces/user-settings';
import { LocationResult } from '../Locations/location-result';
import { UserLocationSave } from '../user-location-save';
import { ObservableReference } from './observable-reference';
import { TimeCheck } from './time-check';

export class LocalStorageObservables {
  public localRef = [
    new ObservableReference(
      'locations',
      new BehaviorSubject<LocationResult>(null as any)
    ),
    new ObservableReference(
      'userSettings',
      new BehaviorSubject<UserSettings>(null as any)
    ),
    new ObservableReference(
      'careAudits',
      new BehaviorSubject<UserLocationSave[]>(null as any)
    ),
    new ObservableReference(
      'timeChecks',
      new BehaviorSubject<TimeCheck[]>(null as any)
    ),
  ];

 

  /*[_locations:string]:TestObj ; 
    [locations:string]:BehaviorSubject<LocationResult>;
*/
  constructor() {
    //this.locations = this._locations.propname!.asObservable()
  }
}

//interface IStringIndex extends Record<string, BehaviorSubject<LocationResult>>{}
