import { UserAuditDetail } from "./user-audit-detail";

export class UserAudit{
    public UserID:number = -1;
    public DisplayName:string = "";
    public AuditPermissions:UserAuditDetail[] = [];
    constructor(values: Object = {}) {        
        Object.assign(this, values);
        if (values.hasOwnProperty('AuditPermissions')) {
            this.AuditPermissions = [];
            ((values as UserAudit)['AuditPermissions'] as UserAuditDetail[]).forEach(element => {
                this.AuditPermissions.push(new UserAuditDetail(element));
            });
        }
    }
}