import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditTemplateMulti } from 'src/app/core-module/models/Audits/Audit-Templates/audit-template-multi';
import { Question } from 'src/app/core-module/models/Audits/question';
import { QuestionAnswer } from 'src/app/core-module/models/Audits/question-answer';
import { DDL } from 'src/app/core-module/models/ddl';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent {

  @Input() currentQuestion:Question;
  @Output() currentQuestionChange = new EventEmitter<Question>();
  currentAnswers: number[] = [];

  selectOption(option: QuestionAnswer) {
    option.Selected = !option.Selected;
    this.currentAnswers = this.currentQuestion.QuestionAnswers.filter((e)=>{return e.Selected}).map(e=>{return e.QuestionAnswerID});
    this.currentQuestion.CompletionInfo.Answer = this.currentAnswers;
    this.currentQuestion.CompletionInfo.QuestionAnswered = this.currentAnswers.length>0;
    this.currentQuestionChange.emit(this.currentQuestion);
  }
}
