import { BasicUser } from "../basic-user";
import { UserGroup } from "./user-group";

export class GroupsHolder {
    Groups: UserGroup[];
    PossibleUsers: BasicUser[];
    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('Groups')) {
            this.Groups = [];
            ((values as GroupsHolder)['Groups'] as UserGroup[]).forEach(element => {
                this.Groups.push(new UserGroup(element));
            });
        }
        if (values.hasOwnProperty('PossibleUsers')) {
            this.PossibleUsers = [];
            ((values as GroupsHolder)['PossibleUsers'] as BasicUser[]).forEach(element => {
                this.PossibleUsers.push(new BasicUser(element));
            });
        }
    }
}