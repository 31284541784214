import { DDL } from "../../ddl";

export class AuditTemplateMulti{
    public Values:number[];
    public List:DDL[];
    constructor(values: Object = {}){
        Object.assign(this, values);    
        if(values.hasOwnProperty('List')){
            this.List = [];
            ((values as AuditTemplateMulti)['List'] as DDL[]).forEach(element => {
                this.List.push(new DDL(element));
            });
        }  
        if(values.hasOwnProperty('Values')){
            this.Values = [];
            if((values as AuditTemplateMulti)['Values'] !=null){
                ((values as AuditTemplateMulti)['Values'] as number[]).forEach(element => {
                    this.Values.push(Number(element));
                });
            }         
        }           
    }
}