import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DDL } from 'src/app/core-module/models/ddl';

@Component({
  selector: 'app-entry-info-toggle',
  templateUrl: './entry-info-toggle.component.html',
  styleUrls: ['./entry-info-toggle.component.scss']
})
export class EntryInfoToggleComponent {

  @Input() heading:string = '';
  @Input() help:string = '';
  @Input() value:boolean = false; 
  @Output() valueChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleChanged(){
    this.valueChange.emit(this.value);
  }

}
