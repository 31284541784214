export class FeaturesList {
    public ViewAudit: boolean = false;
    public SaveAudit: boolean  = false;
    public EditAudit: boolean  = false;
    public EditLocations: boolean = false;
    public EditUsers: boolean = false;
    public ScheduleAudit: boolean = false;
    public DeleteScheduledAudit: boolean = false;

    public HasFeatures: boolean = false;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}