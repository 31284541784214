<div
  class="bg-yellow-300 py-5 rounded-tl-2xl rounded-bl-2xl pl-5 pr-4 ml-5 shadow-[1.0px_8.0px_8.0px_rgba(0,0,0,0.38)] transition-all duration-700 ease-in flex flex-col max-h-full">
  <div (click)="switchShowSidePanel()" class="flex">
    <span class="material-symbols-outlined hover:cursor-pointer">menu</span>
    <h4 *ngIf="showSidePanel" class="text-green-700 font-bold ml-2">
      Contents
    </h4>
  </div>
  <div *ngIf="showSidePanel" class="mt-2 flex-grow overflow-y-auto">
    <ul class="striped-list rounded-sm">
      <li *ngFor="let e of this.currentQuestions" [ngClass]="{
          highlighted: e.CompletionInfo.QuestionAnswered || e.NASelected,
          title: e.QuestionType.ID == 6,
          'text-grey-500 hover:cursor-not-allowed':e.BranchedOut,
          ' hover:cursor-pointer':!e.BranchedOut
        }" class="px-2 py-1 my-[1px]"  (click)="jumpToQuestion(e.GUID,e.BranchedOut)">
        <p class="text-sm">{{ e.QuestionHeader }}</p>
      </li>
    </ul>

  </div>
  <button *ngIf="this.currentQuestions.length > 0 && showSidePanel" class="btn-primary w-full mt-4"
    (click)="goToSummary()">
    Finish
  </button>
</div>