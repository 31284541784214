import { BasicResult } from "../basic-result";
import { SaveUser } from "./save-user";

export class NewUserResult extends BasicResult{
    UserDetails:SaveUser = new SaveUser();
    IsEmailValid:boolean;
    IsEmailInUse:boolean;
    IsUsernameValid:boolean;
    IsUsernameInUse:boolean;
    IsLicenseAvailable:boolean;
}