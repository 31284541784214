<div class="p-2 h-screen overflow-y-auto">
    <h1 class="p-4">Diary</h1>
    <div class="p-4 flex justify-end">
        <button class="btn-primary" (click)="showAdd()"><span class="text-center material-symbols-outlined text-xl align-bottom text-standardwhite self-center hover:cursor-pointer mr-1">calendar_add_on</span> <span class="inline-block align-text-bottom">Add Audit</span></button>
    </div>
    
    
    <div *ngIf="showAddAudit">
        <app-add-diary-event (eventAdded)="updateEvents()"></app-add-diary-event>
    </div>


    <div class="pt-4">
        <app-calendar [allEvents]="allEvents" (FromChanged)="updateDate($event)" (EventDeleted)="updateEvents()"></app-calendar>
        
    </div>
</div>  

