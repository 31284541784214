import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { SaveUser } from 'src/app/core-module/models/UserManagement/save-user';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-current-users',
  templateUrl: './current-users.component.html',
  styleUrl: './current-users.component.scss'
})
export class CurrentUsersComponent {

  private originalList:SaveUser[] = [];
  public mobileList:SaveUser[]=[];
  public isGroup:boolean = false;
  
  
  public editing:boolean = false;
  public currentUser:SaveUser = new SaveUser();
  
  public invalidUserName:boolean = false;
  public isUsernameInUse:boolean = false;
  public invalidDisplayName:boolean = false;
  public invalidEmail:boolean = false;
  public emailUsed:boolean = false;

  constructor(private UMS:UserManagementService, private util:UtilService, private ref:ReferenceService, public router:Router){
    UMS.getManageUsers(this.isGroup ).pipe(tap((a) => {this.originalList = a; this.constructCurrentUsers()})).subscribe();
  }
  constructCurrentUsers(){
    if(this.originalList.length>0){
      this.mobileList.push(new SaveUser({"DisplayName":"Please select","UserID":-1,"Disabled":true}))
      this.originalList.forEach(x=>{
        this.mobileList.push(new SaveUser(x));
        if(this.currentUser.UserID == -1){
          this.currentUser = this.mobileList[0];
        }
      })
    }

  }
  selectUser(user:SaveUser){
    this.currentUser = user;
    this.editing = true;
  }
  resetFlags(){}
  
  switchEditing(){
    this.editing = !this.editing;
    this.currentUser = this.mobileList[0];
  }
  selectMobileUser(){
    this.editing = true;
  }
  validate(){this.resetFlags();
    var isValid = true;
    if (!this.isGroup) {
      if (this.currentUser.Username.length < this.ref.minUsernameLength) {
        this.invalidUserName = true;
        isValid = false;
      }
      if(this.util.hasWhiteSpace(this.currentUser.Username)){
        this.invalidUserName = true;
        isValid = false;
      }
      if (!this.util.validEmail(this.currentUser.Email)) {
        this.invalidEmail = true;
        isValid = false;
      }
    }
    if (this.currentUser.DisplayName.length < this.ref.minUsernameLength) {
      this.invalidDisplayName = true;
      isValid = false;
    }
    this.saveUser();
  }

    saveUser(){
      if(this.isGroup){
        this.currentUser.UserTypeID = 2;
      }else{
        this.currentUser.UserTypeID = 1;
      }
      this.UMS.saveManageUsers(this.currentUser).pipe(tap((a) => {})).subscribe();
    }
    goBack(){
      this.router.navigateByUrl("/settings");
    }
  
}
