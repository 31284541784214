import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';

@Component({
  selector: 'app-edit-entry-options',
  templateUrl: './edit-entry-options.component.html',
  styleUrls: ['./edit-entry-options.component.scss']
})
export class EditEntryOptionsComponent {
  @Input() public audit:FlattenedQuestionHolder;
  @Output() auditChange: EventEmitter<FlattenedQuestionHolder> = new EventEmitter<FlattenedQuestionHolder>();

  auditChanged(){
    this.auditChange.emit(this.audit);
  }
}
