import { Component } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { UtilService } from '../core-module/services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Care Audits';

  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];

  constructor(public utilService: UtilService) {}

  ngOnInit(){
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(event => {this.utilService.isOnline = true}));
    this.subscriptions.push(this.offlineEvent.subscribe(event => {this.utilService.isOnline = false;}));
    this.utilService.isOnline = navigator.onLine;
  }

  ngOnDestroy(): void{
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
