import { FlattenedQuestionHolder } from "./flattened-question-holder";

export class AuditHolder{
    public Audit: FlattenedQuestionHolder;
    public LocationID: number;
    constructor(values: Object = {}){
        Object.assign(this, values);    
        if(values.hasOwnProperty('Audit')){
            this.Audit = new FlattenedQuestionHolder((values as AuditHolder)['Audit'] as FlattenedQuestionHolder) 
         }           
    }
}