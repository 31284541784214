import { Injectable } from '@angular/core';
import { FlattenedQuestionHolder } from '../../models/Audits/flattened-question-holder';
import { Question } from '../../models/Audits/question';
import { isValid } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class AuditValidationService {

  constructor() { }

  remainingQuestions(FQH: FlattenedQuestionHolder, Required: boolean = false): number {
    var counter: number = 0
    FQH.Questions.forEach(e => {
      if (!e.CompletionInfo.QuestionAnswered && e.QuestionType.ID != 6) {
        if (Required) {
          if (e.Required) {
            counter++;
          }
        } else {
          counter++;
        }
      }
    })
    return counter;
  }
  incompleteNonCompliant(FQH: FlattenedQuestionHolder): Question[] {
    var Questions: Question[] = [];
    FQH.Questions.forEach(e => {
      if (e.QuestionAnswers && !this.isCompliantQuestion(e) && e.CompletionInfo.Actions.length == 0 && e.CompletionInfo.QuestionAnswered) {
        Questions.push(e);
      }
    })
    return Questions;
  }
  validCompleteQuestions(FQH: FlattenedQuestionHolder): boolean {
    var isValid = true;
    FQH.Questions.forEach(e => {
      if (e.Required && !e.CompletionInfo.QuestionAnswered) {
        isValid = false;
      }
      if (!this.isCompliantQuestion(e)) {
        if (e.CompletionInfo.Actions.length == 0) {
          isValid = false;
        }
      }
    })
    return isValid;
  }
  isCompliantQuestion(Q: Question): boolean {
    switch (Q.QuestionType.ID) {
      case 1:
        var answers: number[] = [];
        if (Q.CompletionInfo.Answer != null) {
          answers.push(Number(Q.QuestionAnswers.find(e => { return e.QuestionAnswerID == Q.CompletionInfo.Answer })!.ComplianceScore))
        }
        return this.limitCompare(Q.ComplianceLimit, answers);
      case 2:
        var answers: number[] = [];
        if (Q.CompletionInfo.Answer != null) {
          answers.push(Number(Q.QuestionAnswers.find(e => { return e.QuestionAnswerID == Q.CompletionInfo.Answer })!.ComplianceScore))
        }
        return this.limitCompare(Q.ComplianceLimit, answers);
      case 3:
        var answers: number[] = [];
        if (Q.CompletionInfo.Answer != null) {
          Q.CompletionInfo.Answer.forEach((f: number) => {
            answers.push(Number(Q.QuestionAnswers.find(e => { return e.QuestionAnswerID == Number(f) })!.ComplianceScore))
          })
        }
        return this.limitCompare(Q.ComplianceLimit, answers);
    }
    return true;
  }
  limitCompare(limit: number, answers: number[]): boolean {
    var totalScore = 0;
    answers.forEach(e => { totalScore += e });
    return totalScore >= limit;
  }
}
