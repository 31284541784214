<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
    <h1 class="p-4" *ngIf="!isNewLocation">Edit Location</h1><h1 class="p-4" *ngIf="isNewLocation">New Location</h1>
    <div class="mx-4 mt-3 mb-5" *ngIf="isLocationSelected && !isNewLocation">
        <a class="py-1 hover:underline hover:cursor-pointer" (click)="changeLocation()">
            < Change Location</a>
                <div class="border-solid  border-b-[1px] mx-1 my-4 w-full border-b-grey-500"></div>
    </div>

    <div class="mx-4 mt-4">
        <p *ngIf="isLocationSelected && !isNewLocation" class="my-4 text-2xl">Editing:<span class="text-2xl font-semibold">
                {{selectedLocation.LocationName}}</span><span class="ml-2 text-red-600 text-2xl font-bold"
                *ngIf="!selectedLocation.Active">(Inactive)</span></p>
        <div class="mt-4" *ngIf="!isLocationSelected && !isNewLocation">
            <p class="text-2xl font-bold">Select Location</p>
            <div class="p-4" *ngIf="showLocation">
                <div *ngIf="!topLevel" (click)="resetLocations()" class="flex">
                    <span
                        class="text-center material-symbols-outlined text-green-700 self-center mr-1 ">undo</span><span>Reset
                        Locations</span>
                </div>
                <div *ngIf="locationsReady">
                    <div *ngFor="let l of possibleLocations" class="flex w-full my-2">
                        <span
                            class="p-2 md:p-4 flex-grow standardTextSize border-spacing-1.5 border-b-grey-500 border-s-2 flex  align-middle "
                            [ngClass]="{'selected': l.LocationID == selectedLocation.LocationID, 'hover:hover-for-select  ':l.HasPermission, 'bg-grey-100 hover:cursor-not-allowed':!l.HasPermission,' bg-grey-300':l.HasPermission&&l.LocationID!=selectedLocation.LocationID}"
                            (click)="selectLocation(l)">{{l.LocationName}}<span *ngIf="!l.Active" class="ml-2 text-red-600 font-bold">(Inactive)</span></span>
                        <div *ngIf="locationHasChildren(l)" class=" bg-standardwhite "
                            (click)="buildLocationDDL(l.LocationID)">
                            <span
                                class="standardTextSize text-offblack hover:hover-for-select bg-grey-300 border-grey-900 border-spacing-2 border-s h-full px-4 flex items-center">Sub
                                Locations</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="isLocationSelected">
            <div>
                <h4 class="mb-2">Location Name</h4>
                <app-text-box-entry class="col-span-2 mb-2 " [isTextArea]="false"
                    [(value)]="selectedLocation.LocationName" (valueChange)="locationChanged($event)" [isEditing]="isNewLocation"></app-text-box-entry>
                    <p *ngIf="selectedLocation.LocationName.length == 0" class="text-red-600">Location name required.</p>
            </div>
            <div class="mt-3" [ngClass]="{'border-[1px] border-grey-100 border-solid rounded-lg shadow-sm shadow-grey-300 my-4':editingParent}">
                <div class="flex justify-between " [ngClass]="{'mt-2 mx-3':editingParent}">
                    <h4 >Parent Location</h4><p *ngIf="editingParent" (click)="clearParent()" class="text-red-600 hover:underline hover:cursor-pointer">Remove Parent Location</p>
                </div>
                <div *ngIf="!editingParent" class="flex items-center mt-2">
                    <span class="material-symbols-outlined text-sm cursor-pointer px-2" *ngIf="!editingParent && hasEditParent"
                        (click)="switchParentEdit()" >edit</span>
                    <p *ngIf="!hasAvailableParent">Location does not have a parent location <span *ngIf="!hasEditParent">and no valid parent locations</span></p>
                    <p *ngIf="hasAvailableParent">{{getParentLocation().LocationName}}</p>
                </div>
                <div *ngIf="parentListAvailable && editingParent" class="p-4 ">
                    <div>
                        <div *ngIf="!topParentLevel" (click)="setParentList()" class="flex">
                            <span
                                class="text-center material-symbols-outlined text-green-700 self-center mr-1 ">undo</span><span>Reset
                                Locations</span>
                        </div>
                        <div *ngFor="let l of displayParents" class="flex w-full my-2">
                            <span
                                class="p-2 md:p-4 flex-grow standardTextSize border-spacing-1.5 border-b-grey-500 border-s-2 flex justify-between align-middle hover:hover-for-select "
                                [ngClass]="{'selected': l.LocationID == selectedParent.LocationID, ' bg-grey-300':l.LocationID!=selectedParent.LocationID}"
                                (click)="selectParentLocation(l)">{{l.LocationName}}</span>
                            <div *ngIf="parentLocationHasChildren(l)" class=" bg-standardwhite "
                                (click)="updateParentList(l.LocationID)">
                                <span
                                    class="standardTextSize text-offblack hover:hover-for-select bg-grey-300 border-grey-900 border-spacing-2 border-s h-full px-4 flex items-center">Sub
                                    Locations</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col mt-3  lg:w-3/5 xl:w-2/5 ">
                        <button class="btn-primary" (click)="confirmParentLocation()"
                            [disabled]="selectedParent.LocationID == -1"><span
                                *ngIf="selectedParent.LocationID == -1">Select Parent</span><span
                                *ngIf="selectedParent.LocationID != -1">Confirm Parent Location</span></button>
                        <button class="btn-inverse mt-3" (click)="cancelParentChange()">Cancel</button>

                    </div>
                </div>

            </div>
            <div class=" lg:w-3/5 xl:w-2/5 mt-3">
                <div class="flex flex-col">
                    <label class="mr-2 standardTextSize text-xl">Active </label>
                    <app-toggle-switch class="mt-2" [(checked)]="selectedLocation.Active"></app-toggle-switch>

                </div>
                <div class="mt-8">
                    <button class="btn-primary w-full" (click)="saveChanges()" [disabled]="saving"><span *ngIf="!saving">Save</span><span *ngIf="saving">Saving...</span></button>
                </div>
                
            </div>

        </div>
    </div>
</div>