<p class="flex items-center" [ngClass]="{'font-bold':isBold}" *ngIf="!isEditing">
    <span class="material-symbols-outlined text-sm cursor-pointer px-2" (click)="edit()">edit</span>
    {{value.Description}}
</p>
<div class="flex mt-2 mb-2 xl:w-2/3" *ngIf="isEditing" >
    <select [(ngModel)]="value" [compareWith]="optionComparison" class="text-box">
        <option *ngFor="let t of options" [ngValue]="t" class="pr-20">{{t.Description}}</option>
    </select>

    <p class= "right-0 flex items-center px-4">
        <span class="material-symbols-outlined text-green-300 cursor-pointer pr-2" (click)="confirm()">check</span>
        <span class="material-symbols-outlined text-red-600 cursor-pointer" (click)="revert()">close</span>
    </p>
</div>
