<h2 class="p-2">{{dateHeading()}}</h2>
<div class="p-2 border-t border-b border-grey-100 w-full" [ngClass]="{'bg-red-500':isDeleteHighlight}">
    <div *ngIf="!showAreYouSure">
        <div class="flex justify-between">
            <p class="font-semibold">{{event.AuditName}}</p>
            <span class="text-center material-symbols-outlined text-xl align-bottom text-red-600 self-center hover:cursor-pointer mr-1" (pointerenter)="addWarning()" (pointerout)="removeWarning()" [ngClass]="{'text-standardwhite':isDeleteHighlight}" (click)="deleteEvent()">delete</span>
        </div>
        
        <div class="flex justify-between pt-2">
            <p class="inline-block text-sm">{{event.LocationName}}</p>
            <p class="inline-block text-sm">{{event.ScheduledUserName}}</p>    
        </div>
    </div>
    <div *ngIf="showAreYouSure">
        <div class="w-full">
            
            <div class="justify-between bg-red-100 pt-2 px-2 rounded-tl-md rounded-tr-md">
                <h3 class="text-lg pb-2">Are you Sure you Want to Delete This Audit?</h3>
                <p class="font-semibold">{{event.AuditName}}</p>
            </div>
            <div class="flex justify-between p-2 mb-2 bg-red-100 rounded-br-md rounded-bl-md">
                <p class="inline-block text-sm">{{event.LocationName}}</p>
                <p class="inline-block text-sm">{{event.ScheduledUserName}}</p>    
            </div>
            
                <p class="m-0 text-sm">Delete all future occurrances of this series?</p>
            <div class="flex items-center py-2 gap-2">
                <span class="text-xs">No</span>
                <app-toggle-switch [(checked)]="this.event.DeleteSeries"></app-toggle-switch>
                <span class="text-xs">Yes</span>
            </div>
            <div class="flex gap-2 justify-end">
                <button class="btn-negative w-auto h-auto text-sm" (click)="actualDelete()">Delete Audit</button>
                <button class="btn-inverse w-auto h-auto text-sm" (click)="unDelete()">Cancel</button>
            </div>
        </div>
    
    </div>
    

</div>
