import { Component, Input, input } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { ActionHistory } from 'src/app/core-module/models/Audits/action-history';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { ComplianceAction } from 'src/app/core-module/models/Audits/compliance-action';
import { BasicResult } from 'src/app/core-module/models/basic-result';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { Action } from 'src/app/core-module/models/Compliance-Actions/action';
import { ActionResponse } from 'src/app/core-module/models/Compliance-Actions/action-response';
import { ActionUserRequest } from 'src/app/core-module/models/Compliance-Actions/action-user-request';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ComplianceActionService } from 'src/app/core-module/services/compliance-action.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserService } from 'src/app/core-module/services/user.service';
import { UtilService } from 'src/app/core-module/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-action-detail',
  templateUrl: './action-detail.component.html',
  styleUrls: ['./action-detail.component.scss']
})
export class ActionDetailComponent {
  constructor(private router: Router, public CAS: ComplianceActionService, private userHelperService: UserService, private toast: ToastrService, public util: UtilService) {
    if (this.router.getCurrentNavigation()?.extras.state!['Action'] != undefined) {
      this.action = new Action(this.router.getCurrentNavigation()?.extras.state!['Action']);
      this.completeActionInfo();
      this.getActionUsers();
      this.arrangeActionUsers();
    }
    if (this.router.getCurrentNavigation()?.extras.state!['ActionID'] != undefined) {
      this.fetchAction(this.router.getCurrentNavigation()?.extras.state!['ActionID']);
    }
    if (this.router.getCurrentNavigation()?.extras.state!['Dashboard'] != undefined) {
      this.IsDashboard = (this.router.getCurrentNavigation()?.extras.state!['Dashboard']);
    }
    if (this.router.getCurrentNavigation()?.extras.state!['Result'] != undefined) {
      this.IsResultPage = (this.router.getCurrentNavigation()?.extras.state!['Result']);
    }
    /*if (this.action.AuditResultDetailActionID == -1) {
      this.router.navigateByUrl('/home');
    }*/
  }

  public ActionUsers: BasicUser[] = [];
  public _users: BasicUser[] = [];
  public CurrentActionUser: BasicUser = new BasicUser();
  public ActionHistory: ActionHistory[] = [];

  public showAuditDetails: boolean = true;


  public IsDashboard: boolean = false;
  public IsResultPage: boolean = false;

  public expandedPhotoPanel: boolean = false;
  public expandedAttachmentPanel: boolean = false;

  ngOnInit() {

  }
  public action: Action = new Action();

  public editAction: Boolean = false;
  public originalAction: Action = new Action();
  private historyFetched: boolean = false;

  public noteWarning: boolean = false;
  //ar = new ActionResponse();

  public newActionCreator: boolean = false;

  public newQuickAction: ComplianceAction = new ComplianceAction();
  //public quickActionUsers:BasicUser[] = [];

  fetchAction(actionID: number) {
    this.CAS.getActionByID(actionID).pipe(
      tap((a) => {
        this.action = new Action(a)
        this.ActionHistory = this.action.History;
        this.historyFetched = true;
        this.getActionUsers();
        this.arrangeActionUsers();
      })
    ).subscribe();
  }
  completeActionInfo() {
    this.originalAction = new Action(this.action);
    if (this.action.AuditResultDetailActionID != -1 && !this.historyFetched) {
      this.getActionHistory();
    }
  }
  getDateText(date: Date): string {
    return new Date(date).toLocaleDateString();
  }

  editActionSwitch() {
    this.editAction = !this.editAction;
  }
  undoChanges() {

    this.action = new Action(this.originalAction);
    this.setActionUsers();
    this.editActionSwitch();
  }
  actionComments() {
    return this.action.Comments != "";
  }
  validateAction() {
    if ((this.originalAction.Resolved == false && this.action.Resolved == true) || (this.originalAction.Resolved == true && this.action.Resolved == false)) {
      if (this.action.NewNote.length == 0) {
        this.noteWarning = true;
        return;
      } else {
        this.noteWarning = false;
        this.saveAction();
      }
    } else {
      this.saveAction();
    }


  }
  saveAction() {
    this.action.ActionAssignee = this.CurrentActionUser.DisplayName;
    this.action.ActionOwnerID = this.CurrentActionUser.UserID;
    this.action.ActionOwnerTypeID = this.CurrentActionUser.UserTypeID;
    this.CAS.modifyAction(this.action).pipe(
      tap((a) => {
        this.showMessages(a);
        this.getActionHistory();
        if (!a.IsError) {
          this.completeActionChange();
        }
      })
    )
      .subscribe();

  }
  saveNewAction() {
    this.newQuickAction.ParentActionID = this.action.AuditResultDetailActionID;
    this.CAS.saveNewAction(this.newQuickAction).pipe(
      tap((a) => {
        this.newActionComplete(a);
      })
    )
      .subscribe();


  }
  newActionComplete(response: BasicResult) {
    if (response.IsError) {
      this.toast.addToastr(new Toastr({ Type: 3, Message: "Unable to create new action, please try again." }));
     
      //show toaster error
    } else {
           
      this.newQuickAction = new ComplianceAction();
      this.newActionCreator = false; 
      this.toast.addToastr(new Toastr({ Type: 2, Message: "New Action Created." }));
      //show positive toastr
      //reset New Action
    }
  }
  completeActionChange() {
    this.originalAction = new Action(this.action);
    this.editActionSwitch();
  }
  showMessages(response: BasicResult) {
    if (response.IsError) {
      if (response.ErrorMessage == "ACE001") {
        this.toast.addToastr(new Toastr({ Type: 4, Message: "No changes detected." }));
      } else {
        this.toast.addToastr(new Toastr({ Type: 3, Message: "Unable to complete action update." }));
      }
    }
  }
  getActionUsers() {
    var request = new ActionUserRequest();
    request.LocationID = this.action.AuditLocation.LocationID;
    this.CAS.getActionUsers(request).pipe(
      tap((a) => {
        this.insertUsers(a);
      })
    )
      .subscribe();
  }
  insertUsers(users: BasicUser[]) {
    var seq = 1;
    users.forEach(e => {
      this._users.push(new BasicUser({ 'UserID': e.UserID, 'DisplayName': e.DisplayName, 'Seq': seq, 'UserTypeID': e.UserTypeID, 'Valid': true }));
      seq++;
    })
    this.arrangeActionUsers();
    this.setActionUsers();
  }
  arrangeActionUsers() {
    this._users = this.userHelperService.sortUsers(this.ActionUsers);
  }
  /*arrangeActionUsers() {
    this._users = this.ActionUsers.sort((a, b) => { if (a.UserTypeID == b.UserTypeID) { return a.DisplayName.localeCompare(b.DisplayName) } else { return b.UserTypeID - a.UserTypeID } });
    ;
  }*/
  setActionUsers() {
    if (!this._users.some(e => e.UserID == -1)) {
      var newUser = new BasicUser({ 'UserID': -1, 'DisplayName': 'Please Select...', 'Seq': 0, 'UserTypeID': 0 });
      this._users.unshift(newUser);
    }
    this.CurrentActionUser = this._users.find(e => { return e.UserID == -1 })!
    this._users.forEach(e => {
      if (e.UserID == this.action.ActionOwnerID && e.UserTypeID == this.action.ActionOwnerTypeID) {
        this.CurrentActionUser = e;
      }
    })
    if (this.CurrentActionUser.UserID == -1) {
      var actionUser: BasicUser = new BasicUser({ 'UserID': this.action.ActionOwnerID, 'DisplayName': this.action.ActionAssignee, 'Seq': 0, 'UserTypeID': this.action.ActionOwnerTypeID, 'Valid': false });
      this._users.push(actionUser);
      this.CurrentActionUser = actionUser;
    }
  }
  userComparison(a: BasicUser, b: BasicUser): boolean {
    if (a == undefined || b == undefined || a == null || b == null) {
      return false;
    }
    return a.UserID == b.UserID && a.UserTypeID == b.UserTypeID;
  }
  updateCompletedBy() {
    this.action.ResolvedDate = new Date();
    //update time completed at
  }
  updateNewAction() {
    this.newActionCreator = !this.newActionCreator;
  }
  getActionHistory() {
    this.CAS.getActionHistory(this.action.AuditResultDetailActionID).pipe(
      tap((a) => {
        this.ActionHistory = a;
      })
    )
      .subscribe();
  }
  changeAuditDetailView() {
    this.showAuditDetails = !this.showAuditDetails;
  }
  goBack() {
    if (this.IsDashboard) {
      this.router.navigateByUrl("/home");
    } else if (this.IsResultPage) {
      this.router.navigateByUrl("/results");
    } else {
      this.router.navigateByUrl("/actions");
    }
  }

  downloadAttachment(GUID: string) {
    window.open(`${environment.apiUrl}audits/getattachment/` + GUID, '_blank')
  }
  photoSrc(GUID: string): string {
    return `${environment.apiUrl}audits/getphoto/` + GUID;
  }
  switchAttachmentPanel() {
    if (this.action.Attachments.length == 0) {
      return;
    }
    this.expandedAttachmentPanel = !this.expandedAttachmentPanel;
  }
  switchPhotoPanel() {
    if (this.action.Photos.length == 0) {
      return;
    }
    this.expandedPhotoPanel = !this.expandedPhotoPanel;
  }
  filenameReduction(filename: string): string {
    if (filename.length > 22) {
      return filename.substring(0, 8) + "..." + filename.substring(filename.length - 8);
    } else {
      return filename
    }
  }

}
