import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {

  @Input() checked:boolean = false;
  @Output() checkedChange:EventEmitter<boolean> = new EventEmitter<boolean>;
  public guid:string = '';

  constructor(public utils:UtilService){
    this.guid = utils.newGuid();
  }

  public toggleChange(){
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }

}
