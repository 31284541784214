import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { format } from 'date-fns';
import { ScheduledEvent } from 'src/app/core-module/models/Diary/scheduled-event';

@Component({
  selector: 'app-scheduled-audit',
  templateUrl: './scheduled-audit.component.html',
  styleUrl: './scheduled-audit.component.scss',
  // animations: [
  //   trigger(
  //     'inOutAnimation',
  //     [
  //       transition(
  //         ':enter',
  //           [
  //             style({height:0, opacity:0}),
  //             animate('200ms ease-out',style({height:'auto',opacity:1}))
  //           ]  
  //       ),
  //       transition(
  //         ':leave',
  //         [
  //           style({opacity:1, height:'auto'}),
  //           animate('200ms ease-in',style({opacity:0, height:0}))
  //         ]
  //       )
  //     ]
  //   )
  // ]
})
export class ScheduledAuditComponent {

  @Input() event: ScheduledEvent;
  @Input() showDate: boolean = false;
  @Output() DeleteEvent: EventEmitter<ScheduledEvent> = new EventEmitter<ScheduledEvent>();

  public isDeleteHighlight: boolean = false;
  public showAreYouSure: boolean = false;
  
  dateHeading(): string{
    return format(this.event.AuditDate,"EEE dd LLLL");
  }

  addWarning(){
    this.isDeleteHighlight = true;
  }

  removeWarning(){
    this.isDeleteHighlight = false;
  }

  deleteEvent(){
    this.showAreYouSure = true;
    this.isDeleteHighlight = false;
  }

  actualDelete(){
    this.DeleteEvent.emit(this.event);
  }
  unDelete() {
    this.showAreYouSure = false;
  }

}
