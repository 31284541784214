import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function validDateGreaterThan(allowEqual:boolean,_date:Date = new Date()):ValidatorFn{
    return (control:AbstractControl):ValidationErrors|null => {
       
        if(control.value){
            return {};
            /*var correctedDate:Date = new Date(control.value)
            var dateTest:boolean = false;
            if(allowEqual){
                dateTest=_date.getDate()>=correctedDate.getDate();
            }else{
                dateTest=_date.getDate()>correctedDate.getDate();
            }
            if(dateTest){
                return null;
            }else{
                return {'Date less than':true};
            }*/
        }else{
            return {'Date Invalid':true};
        }        
    }
}