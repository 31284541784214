import { AuditLocation } from "./audit-location";

export class LocationLevel {
    public LocationLevelID: number;
    public PocationLevelSeq: number;
    public LocationLevelName: string;
    public Seq: number;
    public Locations: AuditLocation[];
    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('Locations')) {
            this.Locations = [];
            ((values as LocationLevel)['Locations'] as AuditLocation[]).forEach(element => {
                this.Locations.push(new AuditLocation(element));
            });
        }
    }
}