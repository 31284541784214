import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { NewUserResult } from 'src/app/core-module/models/UserManagement/new-user-result';
import { SaveUser } from 'src/app/core-module/models/UserManagement/save-user';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {

  public editingUser:SaveUser = new SaveUser();
  public originalUser:SaveUser = new SaveUser();

  public isEditingName:boolean = false;
  public isEditingEmail:boolean = false;

  public isEditing:boolean = false;

  constructor(private UMS:UserManagementService, public router:Router){
    UMS.getUserProfile().pipe(tap((a) => { this.originalUser = new SaveUser(a);this.setupUser(); })).subscribe();
  }
  setupUser(){
    this.editingUser = new SaveUser(this.originalUser);
  }
  reset(){
    this.setupUser();
  }
  updateEditing(){
    if(this.isEditingName || this.isEditingEmail){
      this.isEditing = true;
    }else{
      this.isEditing = false;
    }
  }
  validateUser(){
    //validate
    this.save();
  }
  save(){
     this.UMS.saveUserProfile(this.editingUser).pipe(tap((a) => { this.handleSaveReturn(a);this.setupUser(); })).subscribe();
  }
  handleSaveReturn(saveReturn:NewUserResult){
    if(saveReturn.IsError){

    }else{
      this.originalUser = new SaveUser(saveReturn.UserDetails);
    }
  }
     
  goBack(){
    this.router.navigateByUrl("/settings");
  }
}
