import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from 'src/app/core-module/models/Audits/question';
import { QuestionAnswer } from 'src/app/core-module/models/Audits/question-answer';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent {

  
  public SelectedOption: QuestionAnswer;
  public showPleaseSelect:boolean = false;
 
  @Input() currentQuestion:Question;
  @Output() currentQuestionChange = new EventEmitter<Question>();

  ngOnChanges() {
    if(this.currentQuestion.CompletionInfo.Answer!=null){      
      this.SelectedOption = this.currentQuestion.QuestionAnswers.find(e=>Number(e.QuestionAnswerID)==Number(this.currentQuestion.CompletionInfo.Answer))!;
    }else{
      var hasDefault:boolean = false;
      this.currentQuestion.QuestionAnswers.forEach(e=>{if(e.IsDefault){this.SelectedOption = e;hasDefault=true}});
      if(hasDefault){
        this.updateSelected()
      }else{
        if(!this.currentQuestion.QuestionAnswers.some(e=>e.QuestionAnswerID==-1)){
          var newQuestion = new QuestionAnswer({'QuestionAnswerID':-1,'AnswerText':'Please Select...','Selected':true});
          this.currentQuestion.QuestionAnswers.unshift(newQuestion)
        }         
        this.SelectedOption = this.currentQuestion.QuestionAnswers.find(e=>{return e.QuestionAnswerID == -1})!; 
      }

    }   
  }
  optionComparison(a:QuestionAnswer, b:QuestionAnswer):boolean{
    return a.QuestionAnswerID == b.QuestionAnswerID;
  }
  updateSelected() {
    this.currentQuestion.CompletionInfo.Answer = this.SelectedOption.QuestionAnswerID;
    this.currentQuestion.CompletionInfo.QuestionAnswered = true;
    this.currentQuestionChange.emit(this.currentQuestion);
  }

}
