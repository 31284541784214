import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { PublicComponentsModule } from '../public/public-components/public-components.module';
import { SecureComponentsModule } from '../secure/secure-components/secure-components.module';
import { ToggleSwitchComponent } from '../shared-module/components/toggle-switch/toggle-switch.component';
import { ToastrComponent } from '../shared-module/components/toastr/toastr.component';
import { ToastrHolderComponent } from '../shared-module/components/toastr-holder/toastr-holder.component';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PublicComponentsModule,
    SecureComponentsModule,
  ],
  providers: [
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'gb'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
