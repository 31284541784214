import { Component, EventEmitter, input, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-box-entry',
  templateUrl: './text-box-entry.component.html',
  styleUrls: ['./text-box-entry.component.scss']
})
export class TextBoxEntryComponent {

  @Input() public value:string;
  @Input() public isBold:boolean = false;
  @Input() public isTextArea: boolean = false;
  @Input() public isEditing: boolean = false;


  @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>
  @Output() public isEditingChange: EventEmitter<boolean> = new EventEmitter<boolean>
  

  private originalValue:string;
 

  edit(){
    this.originalValue = structuredClone(this.value);
    this.isEditing = true;
    this.emitEditing();
  }

  revert(){
    this.value = structuredClone(this.originalValue);
    this.isEditing = false;
    this.emitEditing();
  }

  confirm(){
    this.isEditing = false;
    this.valueChange.emit(this.value);
    this.emitEditing();
  }
  emitEditing(){
    this.isEditingChange.emit(this.isEditing);
  }
}
