import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, catchError, tap } from 'rxjs';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';

@Component({
  selector: 'app-edit-audit',
  templateUrl: './edit-audit.component.html',
  styleUrls: ['./edit-audit.component.scss']
})
export class EditAuditComponent {

  public audit:FlattenedQuestionHolder;
  private originalAudit:FlattenedQuestionHolder;
  public auditTypes:DDL[];

  public showMainInfo: boolean = true;
  public showEntryOptions: boolean = false;
  public showQuestions: boolean = false;
  public showWorkflow: boolean = false;

  public reloading: boolean = false;

  constructor(private router:Router, public audits:AuditService, private toastr:ToastrService){
    if(this.router.url == '/settings/manage/new/audit'){
      this.audit = new FlattenedQuestionHolder({'AuditID':-1});
    }else{
      if (this.router.getCurrentNavigation()?.extras.state!['Audit'] != undefined) {
        
      this.audit = this.router.getCurrentNavigation()?.extras.state!['Audit'] as FlattenedQuestionHolder;
      audits.getAudit(this.audit.AuditID,-1).pipe(
        tap((res) => {
          this.audit = res.QuestionHolder;
          this.originalAudit = structuredClone(res.QuestionHolder);
        })
      ).subscribe();
    }
    }
     /*else {
      // ONLY FOR DEBUG - REMOVE ELSE 
      audits.getAudit(1,-1).pipe(
        tap((res) => {
          this.audit = res.QuestionHolder;
          this.originalAudit = structuredClone(res.QuestionHolder);
          console.log(this.audit);
        })
      ).subscribe();
    }*/

    audits.getAuditTypes().pipe(
      tap((res)=>{
        this.auditTypes = res.Results!;
      })
    ).subscribe();

  }

  reloadAudit(){
    this.reloading = true;
    this.audits.getAudit(this.audit.AuditID,-1).pipe(
      tap((res) => {
        this.audit = res.QuestionHolder;
        this.originalAudit = structuredClone(res.QuestionHolder);
        this.reloading = false;
      })
    ).subscribe();
  }

  saveChanges(){
    if(!this.validateSave()){
      this.toastr.addToastr(new Toastr({Message: "Please complete required fields", Type: 1})) 
      return;
    }
    this.audits.saveAuditChanges(this.audit).pipe(
      tap(res => {
        if(res.IsError){
          this.toastr.addToastr(new Toastr({Message: "An error occurred, please try again later.", Type: 1}))  
        } else {
          this.audit.AuditID = Number(res.ErrorMessage);
          this.toastr.addToastr(new Toastr({Message: "Audit saved.", Type: 2}))  
          this.reloadAudit();
        }
      }),
      catchError((err,n) => {
        this.toastr.addToastr(new Toastr({Message: "An error occurred, please try again later.", Type: 1}))
        return EMPTY;
      })
    ).subscribe();
  }

  validateSave():boolean{
    //Type must be valid
    if(!this.audit.AuditInfo.AuditType){
      return false;
    }
    //Audit name required
    if(this.audit.AuditName.length == 0){
      return false;
    }
    return true;
  }
  resetChanges(){
    this.audit = structuredClone(this.originalAudit);
  }

  showAuditMainInfo(){
    this.showMainInfo = true;
    this.showQuestions = false;
    this.showEntryOptions = false;
    this.showWorkflow = false;
  }

  showAuditEntryOptions(){
    this.showMainInfo = false;
    this.showQuestions = false;
    this.showEntryOptions = true;
    this.showWorkflow = false;
  }

  showAuditQuestions(){
    this.showMainInfo = false;
    this.showQuestions = true;
    this.showEntryOptions = false;
    this.showWorkflow = false;
  }

  showAuditWorkflow(){
    this.showMainInfo = false;
    this.showQuestions = false;
    this.showEntryOptions = false;
    this.showWorkflow = true;
  }
  goBack(){
    this.router.navigateByUrl("/settings/manage/edit");
  }
}
