import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-number-box-entry',
  templateUrl: './number-box-entry.component.html',
  styleUrls: ['./number-box-entry.component.scss']
})
export class NumberBoxEntryComponent {
  @Input() public value:number;
  @Input() public isBold:boolean = false;
  @Output() public valueChange: EventEmitter<number> = new EventEmitter<number>

  private originalValue:number;
  public isEditing: boolean = false;


  edit(){
    this.originalValue = structuredClone(this.value);
    this.isEditing = true;
  }

  revert(){
    this.value = structuredClone(this.originalValue);
    this.isEditing = false;
  }

  confirm(){
    this.isEditing = false;
    this.valueChange.emit(this.value);
  }
}
