export class AuditSummaryResultRequest{
    public UserID:number = -1;
    public AuditID:number = -1;
    public LocationID:number = -1;
    public hasStart:boolean = false;
    public hasEnd:boolean = false;
    public StartDate:Date;
    public EndDate:Date;
    constructor(values: Object = {}){
        Object.assign(this, values);     
    }
}