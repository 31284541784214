
<div class="flex-col">
    <div *ngFor="let r of currentQuestion.QuestionAnswers" class="mt-2 border-solid border-[1px] border-green-400 bg-standardwhite rounded-md flex p-2  align-middle" (click)="updateAnswer(r)">        
        <input class="checked:bg-green-300 text-green-400 accent-transparent self-center" [id]="currentQuestion.GUID + r.QuestionAnswerID"  [checked]="r.Selected" [name]="currentQuestion.GUID" type="radio" (change)="updateAnswer(r)"/>
        <label  class="ml-4">{{r.AnswerText}}</label>
    </div>
    <div *ngIf="this.currentQuestion.CompletionInfo.QuestionAnswered" class="mt-2">
        <button (click)="resetQuestion()" class="btn-negative text-xs"><span
            class="material-symbols-outlined text-xs mr-1 align-bottom text-standardwhite self-center">close</span> Reset Answer</button>
    </div>
</div>
