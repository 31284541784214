import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from 'src/app/core-module/models/Audits/question';
import { DDL } from 'src/app/core-module/models/ddl';
import { inOutAnimation } from 'src/app/shared-module/animations/inOutAnimation';

@Component({
  selector: 'app-edit-question-basic',
  templateUrl: './edit-question-basic.component.html',
  styleUrls: ['./edit-question-basic.component.scss'],
  animations: [inOutAnimation]
})
export class EditQuestionBasicComponent {

  @Input() public question:Question;
  @Input() public answerTypes:DDL[];
  @Output() public questionChange:EventEmitter<Question> = new EventEmitter<Question>();
  @Output() public deleteQuestion:EventEmitter<Question> = new EventEmitter<Question>();

  //public showOptions:boolean = false;

  questionChanged(){
    this.questionChange.emit(this.question);
  }

  toggleShowOptions(){
    this.question.showEditOptions = !this.question.showEditOptions;
    this.questionChange.emit(this.question);
  }

  delQuestion(){
    this.deleteQuestion.emit(this.question);
  }
}
