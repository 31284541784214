import { DDL } from "../ddl";

export class QuestionAnswer{
    public QuestionAnswerID: number;
    public QuestionLinkID: number;
    public AnswerText: string;
    public ComplianceScore: number;
    public IsDefault: boolean;
    public Actionable: boolean;
    public HasBranch: boolean;
    public BranchAnswerQuestionIDs: number[];
    public BranchGUIDs: string[] = [];
    public Seq: number = -1;    
    public Selected:boolean = false;
    public IsNA: boolean = false;
    public Active: boolean = true;
    constructor(values: Object = {}){
        Object.assign(this, values);
    }

}