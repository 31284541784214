<div class="container">

    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
    <div class="border-solid border-2 border-grey-300 mb-1 mt-3 mx-4"></div>
	

    <h1 class="lg:px-8 mt-4 p-2">Select Audit</h1>
    <div *ngIf="audits!=undefined">
        <ul class="mx-4 mt-2">
            <li *ngFor="let a of audits.Audits" (click)="selectAudit(a)">
                <div class="border my-2 border-grey-900 rounded-lg p-4 cursor-pointer hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
                    <h2>{{a.AuditName}}</h2>
                    <p class="pt-4"><span class="text-sm text-grey-900">Last Updated: </span>{{a.AuditInfo.RevisionDate}}</p>
                    <p><span class="text-sm text-grey-900">Revision Notes: </span>{{a.AuditInfo.RevisionNotes}}</p>
                </div>
            </li>
        </ul>
    </div>
    
</div>  
