<!--<button (click)="test()">Test</button>-->

<div class="mx-5 mt-2">
    <div>
        <button class="btn-inverse py-1" (click)="goBack()">
            < Back</button>
    </div>
    <div class="border-solid border-2 border-grey-500 mb-5 mt-2 "></div>
    <div class="lg:mx-5 mx-2">
        <div class="flex">
            <h3 class="mr-4">Audit Details</h3><span (click)="changeAuditDetailView()" *ngIf="!showAuditDetails"
                class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_down</span><span
                (click)="changeAuditDetailView()" *ngIf="showAuditDetails"
                class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_up</span>
        </div>
        <div *ngIf="showAuditDetails">
            <p class="mt-3"><strong>Audit Name:</strong> {{action.AuditText}}</p>
            <p class="mt-2"><strong>Question:</strong> {{action.QuestionHeader}}</p>
            <p *ngIf="action.QuestionBody.length>0" class="mt-2"><strong>Question Body:</strong> {{action.QuestionBody}}
            </p>

            <p class="mt-2"><strong>Result:</strong>
                <span> {{action.Result}}</span>
            </p>
            <p class="mt-2"><strong>Comments:</strong> <span *ngIf="actionComments()"> {{action.Comments}}</span><span
                    class="text-grey-900" *ngIf="!actionComments()"> None</span></p>
        </div>
        <div *ngIf="action.Reference.length>0">
            <div class="border-solid border-[1px] border-grey-100 mt-2"></div>
            <p class="mt-2"><strong>Reference Question:</strong> {{action.ReferenceQuestionHeader}} -
                {{action.ReferenceQuestionBody}}</p>
            <!--<p class="mt-2"><strong>Reference Question Body:</strong> {{action.ReferenceQuestionBody}}</p>-->
            <p class="mt-2"><strong>Reference:</strong> {{action.Reference}}</p>
            <div class="border-solid border-[1px] border-grey-100 mb-5 mt-2"></div>
        </div>
        <div class="flex mt-3">
            <span
                [ngClass]="{'text-green-400 cursor-pointer':action.Attachments.length>0,'text-grey-500 cursor-not-allowed':action.Attachments.length<=0}"
                class="material-symbols-outlined text-3xl align-bottom  self-center"
                (click)="switchAttachmentPanel()">attach_file</span>
            <span
                [ngClass]="{'text-green-400 cursor-pointer':action.Photos.length>0,'text-grey-500 cursor-not-allowed':action.Photos.length<=0}"
                class="material-symbols-outlined text-3xl align-bottom  self-center ml-2"
                (click)="switchPhotoPanel()">photo_camera</span>
        </div>
        <div *ngIf="expandedPhotoPanel" class="px-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md">
            <h4 class="mb-4 mt-2 font-semibold text-green-700">Photos</h4>
            <div class="flex flex-wrap">
                <div *ngFor="let p of action.Photos" class=" ml-2 mb-2 ">
                    <img [src]="photoSrc(p)" class="max-h-52" />
                </div>
            </div>

        </div>
        <div *ngIf="expandedAttachmentPanel" class="p-2 mx-5 border-[1px] border-solid border-grey-900 mb-2 rounded-md">
            <h4 class="mb-4 font-semibold text-green-700">Attachments</h4>

            <div *ngFor="let q of action.Attachments" class="mt-1">
                <p class="underline hover:text-green-700 cursor-pointer" (click)="downloadAttachment(q.GUID)">
                    {{filenameReduction(q.FileName)}}</p>
            </div>

        </div>
    </div>
    <div class="border-solid border-2 border-grey-500 my-5"></div>

    <div class="lg:mx-5 mx-2 flex lg:flex-row flex-col">
        <div class="lg:w-3/5 w-full">
            <h3>Action Details</h3>
            <div class="mt-3">
                <p><strong>Action Created:</strong> {{action.ActionCreator}} - {{getDateText(action.DateRecorded)}}</p>
                <p class="mt-2"><strong>Location:</strong> {{action.AuditLocation.LocationName}}</p>
            </div>
            <div *ngIf="editAction" class="border-solid border-[1px] border-grey-500  mr-5 my-3"></div>
            <div [ngClass]="{'mt-2':!editAction, 'mt-5':editAction}">
                <strong for="ActionDescription">Action Description:</strong>
                <p *ngIf="!editAction" class="">{{action.ActionDescription}}</p>
                <textarea *ngIf="editAction" #actionDescription [(ngModel)]="action.ActionDescription"
                    class="text-box lg:w-2/5 lg:mx-2"></textarea>
                <div class="mt-2">
                    <p *ngIf="!editAction"><strong>Assigned To:</strong> {{action.ActionAssignee}}<span
                            *ngIf="!CurrentActionUser.Valid">*</span></p>
                    <div [ngClass]="{'hidden':!editAction}">
                        <strong>Action Assigned To:</strong>
                        <select class="text-box lg:w-2/5 lg:mx-2" name="ActionUsers" [(ngModel)]="CurrentActionUser"
                            [compareWith]="userComparison">
                            <option *ngFor="let a of _users" [ngValue]="a" [disabled]="a.UserID == -1"
                                [ngClass]="{'font-bold':a.UserTypeID==2}">{{a.DisplayName}}<span
                                    *ngIf="!a.Valid">*</span>
                            </option>
                        </select>

                    </div>
                    <p *ngIf="!CurrentActionUser.Valid" class="mt-2">*The currently assigned user does not have the
                        correct
                        permissions
                        for this action.</p>
                </div>
                <div class="mt-2">
                    <p *ngIf="!editAction" class=""><strong>Action Due:</strong> {{getDateText(action.ActionDeadline)}}
                    </p>
                    <div class="" *ngIf="editAction">
                        <label>
                            <strong>Action Due Date:</strong>
                            <input [owlDateTime]="dt" class="text-box lg:w-2/5 lg:mx-2"
                                [(ngModel)]="action.ActionDeadline" [owlDateTimeTrigger]="dt" />
                            <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
                        </label>
                    </div>
                </div>
                <div *ngIf="action.Resolved && !editAction" class="mt-2">
                    <p><strong>Completed By:</strong> {{action.ResolvedUser}} ({{getDateText(action.ResolvedDate)}})</p>
                </div>
            </div>

            <div class="mt-2">
                <p *ngIf="!editAction"><strong>Status: </strong><span *ngIf="action.Resolved">Closed</span><span
                        *ngIf="!action.Resolved">Open</span></p>
                <div class="flex justify-start my-3 items-center" *ngIf="editAction">
                    <strong>Closed:</strong><app-toggle-switch [(checked)]="action.Resolved"
                        (checkedChange)="updateCompletedBy()" class="ml-2"></app-toggle-switch>
                </div>
                <div *ngIf="editAction">
                    <textarea rows="3" placeholder="Notes" [(ngModel)]="action.NewNote"></textarea>
                    <div *ngIf="noteWarning">
                        <p class="text-red-600">A note must be added when changing the action status.</p>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'mt-1':!editAction, 'mb-5 mt-3':editAction}" class="flex flex-col-reverse lg:block">
                <button *ngIf="!editAction" class="btn btn-primary mt-2" (click)="editActionSwitch()">Edit</button>
                <button *ngIf="editAction" class="btn btn-primary mt-2 lg:mr-2 btn-inverse"
                    (click)="undoChanges()">Cancel</button>
                <button *ngIf="editAction" class="btn btn-primary mt-2 " (click)="validateAction()">Save</button>
            </div>

        </div>
        <div class="lg:p-2 pt-2 flex flex-grow lg:w-2/5 w-full">
            <div class="rounded-lg border-solid border-2 border-grey-100 p-2 flex  flex-col w-full">
                <h3 class="mb-2">Action History</h3>
                <div *ngIf="ActionHistory.length==0">
                    <p class=" ml-2 text-grey-300">No Action History</p>
                </div>
                <div *ngFor="let ah of ActionHistory" class="flex flex-col mb-2">
                    <p class="font-semibold text-green-400 mb-1">{{ah.ChangeDescription}}</p>

                    <p><span class="font-semibold mb-1 mr-1">Date:</span>{{util.humanDate(ah.DateOfChange)}}</p>
                    <p><span class="font-semibold mb-1 mr-1">Previous:</span>{{ah.PreviousValue}}</p>
                    <p><span class="font-semibold mr-1">Current:</span>{{ah.CurrentValue}}</p>
                    <p *ngIf="ah.Notes.length>0"><span class="font-semibold mr-1">Notes:</span>{{ah.Notes}}</p>

                    <div class="w-10/12 border-b-[1px] border-grey-300 border-solid"></div>
                </div>
            </div>

        </div>
    </div>

    <div class="border-solid border-2 border-grey-500 my-5 "></div>
    <div class="lg:mx-5 mx-2">
        <div class="flex" *ngIf="!newActionCreator">
            <button class="btn btn-primary lg:mr-2 lg:w-2/5 w-full" (click)="updateNewAction()">New Action</button>
        </div>
        <app-new-action-quick *ngIf="newActionCreator" (saveNewAction)="saveNewAction()" [(newAction)]="newQuickAction"
            (resetNewAction)="updateNewAction()" [Users]="_users"></app-new-action-quick>

    </div>

    <div class="border-solid border-2 border-grey-500 my-5 "></div>
    <!--<div class="mx-5">
        <h3>Action Updates</h3>
        <div *ngFor="let r of action.ActionResponseList">
            <p>{{getDateText(r.ResponseDate)}}: {{r.Username}} - {{r.Note}} - Resolved: {{r.Resolved}}</p>
        </div>
        <p>History of changes</p>
    </div>-->


</div>