import { BasicResult } from "../basic-result";
import { LocationHolder } from "./location-holder";
import { LocationLevel } from "./location-level";

export class LocationResult extends BasicResult {
    public Locations: LocationHolder;
    public CustomerName: string;
    public Levels: LocationLevel[];
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
        if (values.hasOwnProperty('Levels')) {
            this.Levels = [];
            ((values as LocationResult)['Levels'] as LocationLevel[]).forEach(element => {
                this.Levels.push(new LocationLevel(element));
            });
        }
        if (values.hasOwnProperty('Locations')) {
            this.Locations = new LocationHolder((values as LocationResult)['Locations'] as LocationHolder);
        }
    }
}