import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DDL } from 'src/app/core-module/models/ddl';

@Component({
  selector: 'app-dropdown-entry',
  templateUrl: './dropdown-entry.component.html',
  styleUrls: ['./dropdown-entry.component.scss']
})
export class DropdownEntryComponent {
  @Input() public value:DDL;
  @Input() public isBold:boolean = false;
  @Input() public isTextArea: boolean = false;
  @Input() public options: DDL[];
  @Output() public valueChange: EventEmitter<DDL> = new EventEmitter<DDL>

  private originalValue:DDL;
  public isEditing: boolean = false;


  edit(){
    this.originalValue = structuredClone(this.value);
    this.isEditing = true;
  }

  revert(){
    this.value = structuredClone(this.originalValue);
    this.isEditing = false;
  }

  confirm(){
    this.isEditing = false;
    this.valueChange.emit(this.value);
  }

  optionComparison(a:DDL, b:DDL):boolean{
    return a.ID == b.ID;
  }
}
