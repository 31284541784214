import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiaryEvent } from '../models/Diary/diary-event';
import { environment } from 'src/environments/environment';
import { BasicResult } from '../models/basic-result';
import { ScheduledEvent } from '../models/Diary/scheduled-event';
import { Observable, map } from 'rxjs';
import { BasicUser } from '../models/basic-user';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class DiaryService {

  constructor(private httpClient: HttpClient, private utilService: UtilService) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response',
    responseType: 'json' as const,
    withCredentials: true,
  };

  saveEvent(de: DiaryEvent) {
    de.StartDate = this.utilService.removeTimezoneFromDate(de.StartDate);
    if (de.HasEndDate){
      de.EndDate = this.utilService.removeTimezoneFromDate(de.EndDate);
    }
    return this.httpClient.post<BasicResult>(
      `${environment.apiUrl}diary/saveevent`,
      de,
      this.httpOptions
    );
  }

  getEvents(FromDate: Date){
    FromDate = this.utilService.removeTimezoneFromDate(FromDate);
    let req = {FromDate: FromDate};
    return this.httpClient.post<ScheduledEvent[]>(
      `${environment.apiUrl}diary/getevents`,
      req,
      this.httpOptions
    );
  }

  getDiaryUsers(AuditID: number, LocationID: number): Observable<BasicUser[]>{
    let req = {AuditID: AuditID, LocationID: LocationID};
    return this.httpClient.post<BasicUser[]>(`${environment.apiUrl}diary/getusers`, req, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }

  deleteDiaryEvent(AuditID: number, AuditSeriesID: number, DeleteFuture: boolean){
    let req = {ScheduledAuditID: AuditID, AuditSeriesID: AuditSeriesID, DeleteFutureSeries: DeleteFuture};
    return this.httpClient.post<BasicResult>(`${environment.apiUrl}diary/deleteevent`, req, this.httpOptions);
  }

}
