import { AuditCompletionInfo } from "./audit-completion-info";
import { AuditEntryOptions } from "./audit-entry-options";
import { AuditInfo } from "./audit-info";
import { AuditMetaData } from "./audit-meta-data";
import { QuestionHolder } from "./question-holder";

export class Audit{
    public AuditID: number;
    public VersionID: number;
    public AuditName: string;
    public AuditInfo: AuditInfo;
    public AuditEntryOptions: AuditEntryOptions;
    public AuditCompletionInfo: AuditCompletionInfo;
    public AuditMetaData: AuditMetaData;
    public Questions: QuestionHolder = new QuestionHolder();
    constructor(values: Object = {}){
        Object.assign(this, values);
        if(values.hasOwnProperty('Questions')){
           this.Questions = new QuestionHolder((values as Audit)['Questions'] as QuestionHolder) 
        }        
    }
}