<label
   [htmlFor]="guid"
   class="flex items-center cursor-pointer select-none"
   >
   <div class="relative">
      <input
         type="checkbox"
         [id]="guid"
         class="peer sr-only"
         [checked]="checked"
         (change)="toggleChange()"
         />
      <div
         class="block h-8 rounded-full box w-14"
         [ngClass]="{'bg-grey-900': !checked, 'bg-green-300': checked}"
         ></div>
      <div
         class="absolute flex items-center justify-center bg-standardwhite w-6 h-6 transition rounded-full dot left-1 top-1"
         [ngClass]="{'translate-x-full': checked}"
         ></div>
   </div>
</label>