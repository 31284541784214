import { Component } from '@angular/core';

@Component({
  selector: 'app-assign-audits',
  templateUrl: './assign-audits.component.html',
  styleUrl: './assign-audits.component.scss'
})
export class AssignAuditsComponent {

}
