<div class="container">
    <!--<h3 class="ml-2">My Profile</h3>
    <div class="flex flex-wrap">
        <a href="settings/users/profile" class="btn-primary m-4">Update Profile</a>    
    </div>
    <h3 class="ml-2">Users</h3>
    <div class="flex flex-wrap">
        <a href="settings/users/newuser" class="btn-primary m-4">New User</a>
        <a href="settings/users/usermanagement" class="btn-primary m-4">Existing Users</a>
        <a href="settings/users/existing" class="btn-primary m-4">User Permissions</a>
        <a href="settings/locations/assignlocations" class="btn-primary m-4">User Locations</a> 
        <a href="settings/users/useraudits" class="btn-primary m-4">User Audits</a>
       
    </div>
  
    <h3 class="ml-2">Groups</h3>
    <div class="flex flex-wrap">
        <a href="settings/users/newgroup" class="btn-primary m-4">New Group</a>
        <a href="settings/users/existinggroups" class="btn-primary m-4">Group Permissions</a>
        <a href="settings/users/groups" class="btn-primary m-4">Group Assignment</a>
        <a href="settings/locations/assigngrouplocations" class="btn-primary m-4">Group Locations</a>  
        <a href="settings/users/groupaudits" class="btn-primary m-4">Group Audits</a>
    </div>-->
    <!--<div class="btn-primary m-4 hover:cursor-pointer" (click)="manageLicenses()">Manage Licenses</div>   -->
    
</div>