import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { Question } from 'src/app/core-module/models/Audits/question';

@Component({
  selector: 'app-edit-workflow',
  templateUrl: './edit-workflow.component.html',
  styleUrls: ['./edit-workflow.component.scss']
})
export class EditWorkflowComponent {
  @Input() public audit:FlattenedQuestionHolder;
  @Output() auditChange: EventEmitter<FlattenedQuestionHolder> = new EventEmitter<FlattenedQuestionHolder>();

  ngOnInit(){
    let repeatsection: number = 1;
    let repeatends = this.audit.Questions.filter((q) => {return q.RepeatStartID != -1});
    for (let i = 0 ; i < repeatends.length; i++){
      repeatends[i].RepeatSectionID = repeatsection;
      for (let j = 0; j < repeatends[i].RepeatQuestionIDs.length; j++){
        
      }
    }
  }

  auditChanged(){
    this.auditChange.emit(this.audit);
  }

  moveUp(q: Question){
    if(q.Seq == 1) return;
    const orig = q.Seq;
    this.audit.Questions.find((qu) => qu.Seq == orig-1)!.Seq += 1;
    q.Seq -= 1;
    this.audit.Questions.sort((a, b) => a.Seq - b.Seq);
    this.auditChanged();
  }

  moveDown(q:Question){
    if(q.Seq == this.audit.Questions.length) return;
    const orig = q.Seq;
    this.audit.Questions.find((qu) => qu.Seq == orig+1)!.Seq -= 1;
    q.Seq += 1;
    this.audit.Questions.sort((a, b) => a.Seq - b.Seq);
    this.auditChanged();
  }
}
