import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { MenuOption } from 'src/app/core-module/models/Utils/menu-option';
import { MenuOptionHolder } from 'src/app/core-module/models/Utils/menu-option-holder';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrl: './manageusers.component.scss'
})
export class ManageusersComponent {

  constructor(private userManagement: UserManagementService, private router: Router, private toast: ToastrService){

  }

  manageLicenses(){
    this.userManagement.manageLicenses().pipe(
      tap(res => {
        if (res.length > 0){
          window.location.href = res;
        } else {
          this.toast.addToastr(new Toastr({Type: 3, Message: "An error occurred getting your billing information, please try again later."}));
        }
        
      })
    ).subscribe();
  }
 

}
