export class AuditEntryOptions{
    public CanSync: boolean;
    public AllowCompletionOnDesktop: boolean;
    public AllowPhotos: boolean;
    public ShowSignatureBoxOnDesktop: boolean;
    public AllowRepeats: boolean;
    public HideDiary: boolean;
    public AllowBranching: boolean;
    public HideScore: boolean;
    public TimestampPhotos: boolean;
    public RelaxRequiredQuestions: boolean;
    public NADefaultUnchecked: boolean;
    public ExcludeEmailGrouping: boolean;
    public UseSectionScores: boolean;
    public UpdateDate: Date;
    public UpdateUserID: number;
    constructor(values: Object = {}){
        Object.assign(this, values);     
    }
}