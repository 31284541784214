import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from 'src/app/core-module/models/Audits/question';
import { QuestionAnswer } from 'src/app/core-module/models/Audits/question-answer';
import { StandardQuestionEntry } from 'src/app/core-module/models/Audits/standard-question-entry';
import { DDL } from 'src/app/core-module/models/ddl';
import { inOutAnimation } from 'src/app/shared-module/animations/inOutAnimation';

@Component({
  selector: 'app-edit-question-selections',
  templateUrl: './edit-question-selections.component.html',
  styleUrls: ['./edit-question-selections.component.scss'],
  animations: [inOutAnimation]
})
export class EditQuestionSelectionsComponent {
  
  @Input() public question:Question;
  @Input() public answerTypes:DDL[];
  @Output() public questionChange:EventEmitter<Question> = new EventEmitter<Question>();
  @Output() public deleteQuestion:EventEmitter<Question> = new EventEmitter<Question>();

  public showAnswers:boolean = false;
  public showOptions:boolean = false;
  public showActions:boolean = false;

  questionChanged(){
    this.questionChange.emit(this.question);
  }

  addAnswer(){
    this.question.QuestionAnswers.push(new QuestionAnswer({QuestionAnswerID: -1, AnswerText: 'New Answer', ComplianceScore: "0", QuestionID: -1, IsDefault: false, Actionable: false}));
    this.questionChanged();
  }

  removeAnswer(answer:QuestionAnswer){
    answer.Active = false;
    this.questionChanged();
  }

  addAction(){
    this.question.StandardActions.push(new StandardQuestionEntry({StandardQuestionEntryID: -1, StandardEntryText: 'New Standard Action'}));
    this.questionChanged();
  }

  removeAction(action:StandardQuestionEntry){
    action.Active = false;
    this.questionChanged();
  }

  toggleShowAnswers(){
    this.showAnswers = !this.showAnswers;
  }

  toggleShowOptions(){
    this.question.showEditOptions = !this.question.showEditOptions;
    this.questionChange.emit(this.question);
  }

  toggleShowActions(){
    this.showActions = !this.showActions;
  }

  delQuestion(){
    this.deleteQuestion.emit(this.question);
  }
}
