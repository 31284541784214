import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription, finalize, tap } from 'rxjs';
import { Question } from 'src/app/core-module/models/Audits/question';
import { QuestionAttachment } from 'src/app/core-module/models/Audits/question-attachment';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-question-attachment',
  templateUrl: './question-attachment.component.html',
  styleUrl: './question-attachment.component.scss'
})
export class QuestionAttachmentComponent {
  @Input() showAttachments: boolean;
  @Input() currentQuestion: Question;
  @Output() currentQuestionChange = new EventEmitter<Question>();

  public Attachments: QuestionAttachment[] = [];
  public uploadProgress: number|null = null;
  public uploadSub: Subscription;

  constructor(private auditService:AuditService, private toast: ToastrService){}
 
  onFileSelected(event:Event){
    if ((event.target as HTMLInputElement).files && (event.target as HTMLInputElement).files!.length) {
      const file:File = (event.target as HTMLInputElement)!.files![0];

      let a = new QuestionAttachment();
      a.FileName = file.name;
      a.Length = file.size;
      a.MimeType = file.type;

      let upload$ = this.auditService.uploadFile(a,file).pipe(
        finalize(()=>{
          this.uploadProgress = null;
        })
      );

      this.uploadSub = upload$.subscribe(event => {
        if (event.type == HttpEventType.UploadProgress){
          this.uploadProgress = Math.round(100 * (event.loaded / event.total!));
        }
        if (event.type == HttpEventType.Response){
          a.GUID = event.body!.toString();
          this.currentQuestion.CompletionInfo.Attachments.push(a);
          this.currentQuestionChange.emit(this.currentQuestion);
        }
        
      });    

    }
  }
  /*fetchAttachment(a:QuestionAttachment){
      window.open(`${environment.apiUrl}audits/getattachment/`+ a.GUID,'_blank')
  }*/
  removeAttachment(a:QuestionAttachment){
    var idx = this.currentQuestion.CompletionInfo.Attachments.findIndex((e) => e.GUID == a.GUID);
    if (idx != -1){
      this.auditService.removeFile(a.GUID).pipe(
        tap(res => {
          if(res.IsError){
            this.toast.addToastr(new Toastr({Type: 4, Message: "Unable to remove attachment, please try again."}));
          }else{
            this.toast.addToastr(new Toastr({Type: 1, Message: "Attachment removed."}));
          }
        })
      ).subscribe();
      this.currentQuestion.CompletionInfo.Attachments.splice(idx,1);
      this.currentQuestionChange.emit(this.currentQuestion);
    }
  }

  humanFileSize(size:number) {
    if (size == 0) return '0B';
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return +((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}
}
