import { Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from './reset-password/ResetPasswordComponent';
import { TrialComponent } from "./trial/trial.component";
import { TrialSuccessComponent } from "./trial-success/trial-success.component";

export const PUBLIC_ROUTES: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'resetpassword', component: ResetPasswordComponent },
    { path: 'trial', component: TrialComponent },
    { path: 'trialstart', component: TrialSuccessComponent },
];