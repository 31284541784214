import { Injectable } from '@angular/core';
import { Question } from '../../models/Audits/question';

@Injectable({
  providedIn: 'root'
})
export class AuditGuidService {

  constructor() { }


  public setGUIDs(questions: Question[]) {
    for (var i: number = 0; i < questions.length; i++) {
      questions[i].GUID = crypto.randomUUID();
    }


    questions = questions.sort(
      (a, b) => a.Seq - b.Seq
    );

    for (var i: number = 0; i < questions.length; i++) {
      if (i == 0) {
        questions[i].PrevQ = '-1';
      } else {
        questions[i].PrevQ = questions[i - 1].GUID;
      }
      if (i == questions.length - 1) {
        questions[i].NextQ = '-1';
      } else {
        questions[i].NextQ = questions[i + 1].GUID;
      }
      /*if (questions[i].IsRepeatEnd) {
        var RestartID = questions[i].RepeatStartID;
        questions.forEach((element) => {
          if (
            element.QuestionID >= RestartID &&
            element.QuestionID < questions[i].QuestionID
          ) {
            questions[i].RepeatQuestions.push(element);
          }
        });
      }*/
    }

  }
  public setBranchGUIDs(questions: Question[]) {
    questions.forEach(q => {
      if (q.IsBranching) {
        q.QuestionAnswers.forEach(a => {
          a.BranchAnswerQuestionIDs.forEach(b=>{
                a.BranchGUIDs.push(questions.find(x => x.QuestionID == b)!.GUID);
          })     
          //questions.find(x => x.QuestionID == a.BranchAnswerQuestionID)!.PrevQ = q.GUID;
        })
      }
    })
  }
  public orderFlattenedQuestions(questions: Question[]): Question[] {
    var returnList: Question[] = [];
    var firstQuestion = questions.find((e) => {
      return e.PrevQ == '-1';
    })!;
    returnList.push(firstQuestion);
    var nextQuestion = firstQuestion.NextQ;
    for (let i = 1; i < questions.length; i++) {
      var currentQ: Question = this.getFlattenedQuestionByGUID(nextQuestion, questions);
      if (currentQ != undefined && nextQuestion != '-1') {
        returnList.push(currentQ);
        nextQuestion = currentQ.NextQ;
      }
    }
    questions = returnList;
    return questions;
  }
  public getFlattenedQuestionByGUID(GUID: string, questions: Question[]): Question {
    var selectedQuestion = questions.find((e) => {
      return e.GUID == GUID;
    })!;
    //this.checkAutoCloseSidePanel();
    return selectedQuestion;
  }
}
