import { BasicResult } from "../basic-result";
import { Audit } from "./audit";
import { FlattenedQuestionHolder } from "./flattened-question-holder";

export class AuditResult extends BasicResult{

    public QuestionHolder:FlattenedQuestionHolder;
    constructor(values: Object = {}){
        super();
        Object.assign(this, values); 
        if(values.hasOwnProperty('QuestionHolder')){
            this.QuestionHolder = new FlattenedQuestionHolder((values as AuditResult)['QuestionHolder'] as FlattenedQuestionHolder) 
         }       
    }
}