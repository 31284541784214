<div class="flex md:flex-row flex-col md:justify-between min-[4rem] mt-4 mb-2">
  <h2 *ngIf="showHeader">{{ header }}</h2>
  <div *ngIf="!showHeader">
    <h2> {{ auditName }} </h2>
    <h4><span class="text-green-300 h5span">&#64;</span>{{locationName}}</h4>
  </div>

  <div class="flex flex-col md:items-end" *ngIf="showHeader">
    <h2 class="text-sm font-bold  md:text-right">{{ auditName }}</h2>
    <h2 class="text-sm md:text-right">{{ locationName }}</h2>
  </div>
</div>