import { BasicResult } from "../basic-result";
import { Action } from "./action";

export class ActionResult extends BasicResult{
    public Actions:Action[] = [];
    constructor(values: Object = {}){
        super();
        Object.assign(this, values);
        if(values.hasOwnProperty('Actions')){
            this.Actions = [];
            ((values as ActionResult)['Actions'] as Action[]).forEach(element => {
                this.Actions.push(new Action(element));
            });
        }
    }
}