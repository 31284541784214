<p class="flex items-center" [ngClass]="{'font-bold':isBold}" *ngIf="!isEditing">
    <span class="material-symbols-outlined text-sm cursor-pointer px-2" (click)="edit()">edit</span>
    {{value}}
</p>
<div class="relative mt-2 mb-2 xl:w-2/3" *ngIf="isEditing" >
    <input type="number" [(ngModel)]="value" class="text-box pr-20">
    <p class="absolute inset-y-0 right-0 flex items-center px-4">
        <span class="material-symbols-outlined text-green-300 cursor-pointer pr-2" (click)="confirm()">check</span>
        <span class="material-symbols-outlined text-red-600 cursor-pointer" (click)="revert()">close</span>
    </p>
</div>
