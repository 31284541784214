import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import {AuditLocation} from '../../core-module/models/Locations/audit-location';
import { Audit } from 'src/app/core-module/models/Audits/audit';
import { LocationHolder } from 'src/app/core-module/models/Locations/location-holder';
import { LocationResult } from 'src/app/core-module/models/Locations/location-result';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { LocalStorageService } from 'src/app/core-module/services/local-storage.service';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { UserLocationSave } from 'src/app/core-module/models/user-location-save';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { UserSettings } from 'src/app/core-module/interfaces/user-settings';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent {

  selectedAudit: FlattenedQuestionHolder = new FlattenedQuestionHolder();
  locations: LocationHolder[] = [];
  selectedLocations: LocationHolder[] = [];
  topLevel: boolean = true;
  selectedLocation: LocationHolder = new LocationHolder();
  canStart: boolean = false;
  locationResult: LocationResult;
  areYouSure:boolean = false;
  noLocations:boolean = false;

  constructor(private router: Router, private referenceService: ReferenceService, private auditService:AuditService, private authService:AuthService){
    this.selectedAudit = new FlattenedQuestionHolder(this.router.getCurrentNavigation()?.extras.state!['audit']);
    this.referenceService.getLocationsByAuditID(this.selectedAudit.AuditID,2).pipe(
      tap(res =>{
        if(res!=null){
          if (res.hasOwnProperty('body')){
            this.locationResult = <LocationResult>res.body;            
          } else {
            this.locationResult = <LocationResult>res;
          }
       
          this.setCurrentLocationLevel();
        }
        if(this.locationResult.Locations.SubLocations.length == 0 || res == null){
          this.noLocations = true;
        }
      })
    ).subscribe();
  }

  ngOnInit(){
    if(this.selectedAudit.AuditID == -1){
      this.router.navigateByUrl('/startaudit'); 
    }
  }

  setCurrentLocationLevel(){
    this.locations = this.locationResult.Locations.SubLocations;
      this.initialPopulation(this.locations[0]);  
      this.resetLocations()    
  }
  initialPopulation(l:LocationHolder){
    this.selectedLocations = this.locations;
    //this.selectedLocation = this.locations[0];

  }
  chooseSublocation(l:LocationHolder){
    this.topLevel = false;
        this.selectedLocations = l.SubLocations;
        //this.selectedLocation = new LocationHolder();
    //this.selectedLocation = l.SubLocations[0];
  }

  selectLocation(l:LocationHolder){
    if(l.Location.HasPermission){
      this.canStart = true;
      this.areYouSure = false;
      this.selectedLocation = l;
    }else{
      this.canStart = false;
    }


    
  }

  resetLocations(){
    this.topLevel = true;
    this.canStart = false;
    this.selectedLocations = this.locations;
    this.areYouSure = false;
  }

  startAudit(){
    if(this.isCurrentAudit()){
    }else{
      if(this.selectedLocation.Location.LocationID!=-1){
        this.router.navigateByUrl('/audit',{state: {"location": this.selectedLocation.Location,"audit":this.selectedAudit}});
      }      
    }
  }
  jumpToAudit(){
    var fullAudit:FlattenedQuestionHolder = new FlattenedQuestionHolder();
    var username:string = "";
    this.authService.getUserName()
    .pipe(
      tap((a) => {
        username = a.Username;
      })
    )
    .subscribe();
    var fullAuditDetails = this.auditService.getLocalAudit(this.selectedAudit.AuditID,this.selectedLocation.Location.LocationID,username);
    //Add fullaudit from local data then enable below
    this.router.navigateByUrl('audit', { state: { "SavedAudit": fullAuditDetails.FlattenedQuestions[0], "SavedLocation": fullAuditDetails.Location } });
  }
  areReadyToClear(){
    this.areYouSure = true;
    
  }
  cancelReadyToClear(){
    this.areYouSure = false;
  }
  removeAudit(){
    var username:string = "";
    this.authService.getUserName()
    .pipe(
      tap((a) => {
        username = a.Username;
      })
    )
    .subscribe();
    this .auditService.removeLocalAudit(this.selectedAudit.AuditID,this.selectedLocation.Location.LocationID,username);
    this.startAudit();
  }
  isCurrentAudit():boolean{
    var username: string = "";
    this.authService.getUserName().pipe(tap((e)=>{
      if(e!=null){
        username = e.Username;
      }
    })).subscribe();
    var isCurrent:boolean = false;
    var auditID = this.selectedAudit.AuditID;
    var locationID = this.selectedLocation.Location.LocationID;
    this.auditService.getLocalAudits().pipe(tap((e)=>{
      if(e != null){
        var temp = <UserLocationSave[]>e
        temp.forEach(f=>{
          if(f.Location.LocationID == locationID && f.Username == username){
            f.FlattenedQuestions.forEach(g=>{
              if(g.AuditID == auditID){
                isCurrent = true;
              }
            })
          }
        })
      }
    })).subscribe();
    return isCurrent;
  }
}
