import { BasicResult } from "../basic-result";
import { AuditLocation } from "../Locations/audit-location";
import { FeaturesList } from "../Locations/features-list";
import { PermissionsUser } from "./permissions-user";

export class UserManagementResult extends BasicResult{
    FeaturesList: FeaturesList;
    Users:PermissionsUser[] = [];
    Locations:AuditLocation[] = [];
    IsGroup:boolean = false;
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
        if (values.hasOwnProperty('Users')) {
            this.Users = [];
            ((values as UserManagementResult)['Users'] as PermissionsUser[]).forEach(element => {
                this.Users.push(new PermissionsUser(element));
            });
        }
        if (values.hasOwnProperty('Locations')) {
            this.Locations = [];
            ((values as UserManagementResult)['Locations'] as AuditLocation[]).forEach(element => {
                this.Locations.push(new AuditLocation(element));
            });
        }
    }
}