import { Injectable } from '@angular/core';
import { QuestionAnswer } from '../../models/Audits/question-answer';
import { DDL } from '../../models/ddl';

@Injectable({
  providedIn: 'root'
})
export class AuditQuestionsService {

  constructor() { }

  public ConvertQuestionAnswerToDDL(QA:QuestionAnswer):DDL{
    return new DDL({'ID':QA.QuestionAnswerID,'Description':QA.AnswerText,'Seq':QA.Seq, 'DefaultSelection':QA.IsDefault})
}
}
