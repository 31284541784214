<div class="container p-4">
    <h1 class="p-4">Settings</h1>
   <div class="mx-4 mt-4">

        <app-menu-holder class=" " [optionsHolder]="Profile"></app-menu-holder>
        <app-menu-holder class=" " [optionsHolder]="UsersMenu"></app-menu-holder>
        <app-menu-holder  class=" "  [optionsHolder]="GroupsMenu"></app-menu-holder>
        <app-menu-holder [optionsHolder]="AuditsMenu"></app-menu-holder>
        <app-menu-holder [optionsHolder]="LocationsMenu"></app-menu-holder> 
        <app-manageusers></app-manageusers>
        <!--<app-manageaudits></app-manageaudits>
    <app-manageusers></app-manageusers>
    <app-managelocations></app-managelocations>-->
 
   </div>
</div>
