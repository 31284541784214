import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tap } from 'rxjs';
import { ScheduledEvent } from 'src/app/core-module/models/Diary/scheduled-event';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { DiaryService } from 'src/app/core-module/services/diary.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';

@Component({
  selector: 'app-day-detail',
  templateUrl: './day-detail.component.html',
  styleUrl: './day-detail.component.scss'
})
export class DayDetailComponent {

  @Input() selectedDate: Date | null = null;
  @Input() events: ScheduledEvent[] = [];

  @Output() DeleteEvent: EventEmitter<ScheduledEvent> = new EventEmitter<ScheduledEvent>();

  constructor(public DiaryService: DiaryService, public toastrService: ToastrService){}

  deleteEvent(event: ScheduledEvent){
    
    this.DiaryService.deleteDiaryEvent(event.ScheduledAuditID,event.AuditSeriesID,event.DeleteSeries).pipe(
      tap(res => {
        if (!res.body?.IsError){
          //success
          this.toastrService.addToastr(new Toastr({Message:"Event deleted.",Type:2}));
          this.DeleteEvent.emit(event);
        } else {
          //error
          console.error(res.body);
        }
      })
    ).subscribe();
    
  }

}
