import { Component, Directive, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { parseISO } from 'date-fns';
import { Question } from 'src/app/core-module/models/Audits/question';
@Directive({
  selector: '[hash]',
})
export class HashDirective{
  @Input() hash: string;
  constructor(public vcRef: ViewContainerRef){}
}

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent {
  public selectedDate:Date = new Date();
  public selectedTime:Date = new Date();
  public selectedCalendarDate:Date = new Date();

  @Input() width:string = '1/5';

  @Input() currentQuestion:Question;
  @Output() currentQuestionChange = new EventEmitter<Question>();


  public binding1:OwlDateTimeComponent<any>;

  ngOnChanges(){
    
    this.validateIncomingDate(this.currentQuestion.CompletionInfo.Answer);
 
  }
  ngAfterViewInit(){

  }
  validateIncomingDate(value:Date){
    if(value == null){
      this.selectedDate = new Date();
      this.selectedTime = new Date();
      this.selectedCalendarDate = new Date();
    }else{
      if(typeof value == 'string'){
        value = parseISO(value);
      }
      this.selectedDate = value;
      this.selectedTime = value;
      this.selectedCalendarDate = value;
    }
    this.updateCurrentDate();
  } 
  updateCurrentDate(){
    this.selectedDate.setTime(this.selectedTime.getTime());
    this.selectedDate.setDate(this.selectedCalendarDate.getDate());
    this.currentQuestion.CompletionInfo.Answer = this.selectedDate;
    this.currentQuestion.CompletionInfo.QuestionAnswered = true;
    this.currentQuestionChange.emit(this.currentQuestion);
  }





}


