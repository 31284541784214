import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from 'src/app/core-module/models/Audits/question';

@Component({
  selector: 'app-audit-contents',
  templateUrl: './audit-contents.component.html',
  styleUrls: ['./audit-contents.component.scss']
})
export class AuditContentsComponent {

  //showSidePanel: boolean = true;
  //sidePanelUserChange: boolean = false;
 
  @Input() showSidePanel:boolean;
  @Output() showSidePanelChange = new EventEmitter<boolean>();
  @Input() sidePanelUser:boolean;
  @Output() sidePanelUserChange = new EventEmitter<boolean>();;
  @Input() currentQuestions: Question[] = [];
  @Input() currentQuestion:Question = new Question();
  @Output() updateSidePanel = new EventEmitter<boolean>();
  @Output() questionGUID = new EventEmitter<string>();
  @Output() summary = new EventEmitter();

  switchShowSidePanel() {
    if (this.showSidePanel) {
      this.showSidePanelChange.emit(!this.showSidePanel);
    } else {
      this.showSidePanelChange.emit(!this.showSidePanel);
    }
    this.sidePanelUserChange.emit(true);
  }
  jumpToQuestion(GUID: string,BranchedOut:boolean) {
    if(!BranchedOut){this.questionGUID.emit(GUID)};
  }
  goToSummary(){
    this.summary.emit();
    this.showSidePanelChange.emit(!this.showSidePanel);
  }
}
