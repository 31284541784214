import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from 'src/app/shared-module/layout/public/public.component';
import { AppRoutingModule } from 'src/app/root-module/app-routing.module';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from '../login/login.component';
import { ResetPasswordComponent } from '../reset-password/ResetPasswordComponent';
import { ToastrComponent } from 'src/app/shared-module/components/toastr/toastr.component';
import { TrialComponent } from '../trial/trial.component';
import { TrialSuccessComponent } from '../trial-success/trial-success.component';


@NgModule({
  declarations: [
    PublicComponent,
    LoginComponent,
    ResetPasswordComponent,
    TrialComponent,
    TrialSuccessComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule
  ]
})
export class PublicComponentsModule { }
