import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import { UserManagementResult } from '../models/UserManagement/user-management-result';
import { GroupsHolder } from '../models/UserManagement/groups-holder';
import { UserGroup } from '../models/UserManagement/user-group';
import { SaveUser } from '../models/UserManagement/save-user';
import { NewUserResult } from '../models/UserManagement/new-user-result';
import { UserLocationsHolder } from '../models/UserManagement/user-locations-holder';
import { UserLocation } from '../models/UserManagement/user-location';
import { AuditLocation } from '../models/Locations/audit-location';
import { BasicUser } from '../models/basic-user';
import { BasicResult } from '../models/basic-result';
import { UserAudit } from '../models/UserManagement/user-audit';
import { TrialSignupOptions } from '../models/Trial/trial-signup-options';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private httpClient: HttpClient) {
  }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response',
    responseType: 'json' as const,
    withCredentials: true,
  };
  userManagementList(isGroup:boolean): Observable<UserManagementResult> {
    return this.httpClient.post<UserManagementResult>(`${environment.apiUrl}users/getuserlist`,isGroup, this.httpOptions).pipe(
      map(res => {
        return new UserManagementResult(res.body!);
      })
    );
  }
  saveUserManagementList(result:UserManagementResult):Observable<UserManagementResult>{
    return this.httpClient.post<UserManagementResult>(`${environment.apiUrl}users/saveuserpermissions`,result, this.httpOptions).pipe(
      map(res => {
        return new UserManagementResult(res.body!);
      })
    );
  }
  getGroupList():Observable<GroupsHolder>{
    return this.httpClient.get<GroupsHolder>(`${environment.apiUrl}users/getGroups`, this.httpOptions).pipe(
      map(res => {
        return new GroupsHolder(res.body!);
      })
    );
  }
  saveGroupUpdate(groupReturn:UserGroup):Observable<GroupsHolder>{
    return this.httpClient.post<GroupsHolder>(`${environment.apiUrl}users/savegroupusers`,groupReturn, this.httpOptions).pipe(
      map(res => {
        return new GroupsHolder(res.body!);
      })
    );
  }
  newUser(newUser:SaveUser):Observable<NewUserResult>{
    return this.httpClient.post<NewUserResult>(`${environment.apiUrl}users/savenewuser`,newUser, this.httpOptions).pipe(
      map(res => {
        return new NewUserResult(res.body!);
      })
    );
  }

  getUserLocationsList(isGroup:boolean):Observable<UserLocationsHolder>{
    return this.httpClient.post<UserLocationsHolder>(`${environment.apiUrl}users/getlocationusers`,isGroup, this.httpOptions).pipe(
      map(res => {
        return new UserLocationsHolder(res.body!);
      })
    );
  }
  saveUserLocationChange(location:UserLocation):Observable<boolean>{
    return this.httpClient.post<boolean>(`${environment.apiUrl}users/savelocationusers`,location, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  getEditableLocations():Observable<AuditLocation[]>{
    return this.httpClient.get<AuditLocation[]>(`${environment.apiUrl}locations/geteditlocations`, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  getManageUsers(isGroup:boolean):Observable<SaveUser[]>{
    return this.httpClient.post<SaveUser[]>(`${environment.apiUrl}users/getmanageusers`,isGroup, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  saveManageUsers(updatedUser:SaveUser):Observable<BasicResult>{
    return this.httpClient.post<BasicResult>(`${environment.apiUrl}users/savemanageduser`,updatedUser, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  getUserProfile():Observable<SaveUser>{
    return this.httpClient.get<SaveUser>(`${environment.apiUrl}users/getprofile`, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  saveUserProfile(userUpdate:SaveUser):Observable<NewUserResult>{
    return this.httpClient.post<NewUserResult>(`${environment.apiUrl}users/saveprofile`,userUpdate, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  manageLicenses():Observable<string>{
    return this.httpClient.post<string>(`${environment.apiUrl}licensing/createportalsession`,{}, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  getUserAudits(isGroup:boolean):Observable<UserAudit[]>{
    return this.httpClient.post<UserAudit[]>(`${environment.apiUrl}users/getuserauditlist`,isGroup, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  saveUserAuditChange(userUpdate:UserAudit):Observable<BasicResult>{
    return this.httpClient.post<BasicResult>(`${environment.apiUrl}users/saveuseraudit`,userUpdate, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  createCheckoutSession(signup: TrialSignupOptions):Observable<string>{
    return this.httpClient.post<string>(`${environment.apiUrl}licensing/trialsignup`,signup, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  finaliseTrial(GUID: string, SessionID: string):Observable<string>{
    return this.httpClient.post<string>(`${environment.apiUrl}licensing/trialsignup`,{"GUID": GUID, "SessionID": SessionID}, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
}
