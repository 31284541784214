import { Question } from "./question";

export class QuestionHolder{
    public Question: Question = new Question();
    public Seq: number;
    public Sections: QuestionHolder[];
    constructor(values: Object = {}){
        Object.assign(this, values);
        if(values.hasOwnProperty('Sections')){
            this.Sections = [];
            ((values as QuestionHolder)['Sections'] as QuestionHolder[]).forEach(element => {
                this.Sections.push(new QuestionHolder(element));
            });
        }
        if(values.hasOwnProperty('Question')){
           this.Question = new Question((values as QuestionHolder)['Question']) 
        }        
    }

}