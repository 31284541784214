import { ComplianceAction } from "./compliance-action";
import { QuestionAttachment } from "./question-attachment";

export class QuestionCompletionInfo{
    public QuestionID: number;
    public Photos: string[] = [];
    public Attachments: QuestionAttachment[] = [];
    public Comments: string = '';
    public Actions: ComplianceAction[] = [];
    public Answer:any|null = null;
    public QuestionAnswered:boolean = false;
    public NonCompliant:boolean = false;
    constructor(values: Object = {}){
        Object.assign(this, values);
        if(values.hasOwnProperty('Actions')){
            this.Actions = [];
            ((values as QuestionCompletionInfo)['Actions'] as ComplianceAction[]).forEach(element => {
                this.Actions.push(new ComplianceAction(element));
            });
        }
        if(values.hasOwnProperty('Photos')){
            this.Photos = [];
            ((values as QuestionCompletionInfo)['Photos'] as String[]).forEach(element => {
                this.Photos.push(element.toString());
            });
        }
        if(values.hasOwnProperty('Attachments')){
            this.Attachments = [];
            ((values as QuestionCompletionInfo)['Attachments'] as QuestionAttachment[]).forEach(element => {
                this.Attachments.push(new QuestionAttachment(element));
            });
        }
    }
}