<div class="container p-4">

    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
		
    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>


    <h1 class="p-4">Group Assignment</h1>
    <div *ngIf="!noGroups">
        <div class="mt-4 mx-4">
            <p class="text-2xl font-bold">Select Group</p>
            <select class="mt-2 mr-4 text-box xl:w-2/5" [(ngModel)]="selectedGroup" (change)="loadGroup()">
                <option [disabled]="g.UserID == -1" *ngFor="let g of groups.Groups" [ngValue]="g">{{g.DisplayName}}
                </option>
            </select>
        </div>



        <div class="flex-col mx-4 mt-8" *ngIf="groupSelected">
            <div class="flex  items-baseline mb-4 flex-col">

                <p class="font-bold mr-4 text-green-700 text-xl">{{loadedGroup.DisplayName}}</p>
            </div>
            <div class="mt-2 border-solid ">
                <table class="xl:w-1/5 lg:w-2/5 my-4 w-full">
                    <thead>
                        <tr class="pb-3">
                            <th class="text-left">Name</th>
                            <th class="text-center">Group Member</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of completeUserList">
                            <td>
                                {{u.DisplayName}}
                            </td>
                            <td class="flex justify-center py-2">
                                <app-toggle-switch [(checked)]="u.Selected"></app-toggle-switch>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <button class="btn-primary mr-4" (click)="saveGroup()">Save</button>
                <button class="btn-warning" (click)="resetGroup()">Undo</button>
            </div>
        </div>
    </div>
    <div *ngIf="noGroups" class="mx-6 mt-4">
        <p class="text-red-600 text-xl">No Group Editing Permissions</p>
    </div>
</div>