<div *ngIf="showWebcam" class="mt-4 border border-grey-900 rounded-md bg-standardwhite p-4">
    <div class="grid grid-cols-1 lg:grid-cols-2">
        <div class="">
            <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)" [width]="640" [height]="360"></webcam>
            <button (click)="takePicture()" class="btn-primary-full"><span class="material-symbols-outlined text-xl mr-1 align-bottom text-standardwhite self-center">photo_camera</span> <span class="inline-block align-text-bottom">Take Picture</span></button>
        </div> 
        
        <div class="flex flex-wrap w-full p-4">
            <div *ngFor="let p of currentQuestion.CompletionInfo.Photos" class="">
                <div class="relative p-4">
                    <img [src]="p" width="200" class=""/>
                    <div class="error-badge hover:cursor-pointer" (click)="removePhoto(p)"><span class="block text-center material-symbols-outlined text-xl align-bottom text-standardwhite self-center hover:cursor-pointer">delete</span></div>
                </div>
                
            </div>
            
        </div>
    </div>
    
</div>

