import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { ComplianceAction } from 'src/app/core-module/models/Audits/compliance-action';
import { Action } from 'src/app/core-module/models/Compliance-Actions/action';
import { ActionHolder } from 'src/app/core-module/models/Compliance-Actions/action-holder';
import { ActionResponse } from 'src/app/core-module/models/Compliance-Actions/action-response';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ComplianceActionService } from 'src/app/core-module/services/compliance-action.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss']
})
export class ActionPanelComponent {
  @Input() action: Action;
  @Output() Refresh = new EventEmitter();

  updateState: boolean = false;
  ar = new ActionResponse();
  noteWarning:boolean = false;

  disabledButton:boolean = false;

  constructor(public CAS: ComplianceActionService, public router:Router, private utilService:UtilService, private toast: ToastrService) { }

  getDeadlineText(deadline: Date): string {
    return  this.utilService.humanDate(new Date(deadline));
  }

  hasDeadlinePassed(deadline: Date): boolean {
    if (this.stripTime(deadline) < this.stripTime(new Date())) {
      return true;
    };
    return false;
  }
  isDeadlineDue(deadline: Date): boolean {

    if (this.stripTime(deadline).toString() == this.stripTime(new Date()).toString()) {
      return true;
    };
    return false;
  }
  
  updateAction(action: Action) {
    this.disabledButton = true;
    if(this.ar.Note.length == 0){
      this.noteWarning = true;
      return;
    }else{
      this.noteWarning = false;
    }
    this.ar.ActionID = action.AuditResultDetailActionID;    
    this.CAS.updateAction(this.ar).pipe(
      tap((a) => {   
        if(a.IsError){
          this.toast.addToastr(new Toastr({Type: 4, Message: "Unable to update action, please try again."}));
        }else{
          this.toast.addToastr(new Toastr({Type: 2, Message: "Action updated."}));
        }
        this.Refresh.emit();
        this.disabledButton = false;
      })
    )
      .subscribe();
 
  }

  changeUpdateState() {
    this.updateState = !this.updateState;
  }
  clearChanges() {
    this.ar = new ActionResponse();
    this.changeUpdateState();
  }

  stripTime(input: Date): Date {
    input = new Date(input);
    input.setHours(0, 0, 0, 0);
    return input;
  }
  detailAction(action:Action){
    var isDashboard = false;
    if(this.router.url == "/home"){isDashboard = true};
    this.router.navigateByUrl('action',{state: {"Action" : action,"Dashboard":isDashboard}})
  }

}
