export class StandardQuestionEntry{
    public StandardQuestionEntryID: number = -1;
    public StandardEntryText:string = "";
    public IsDefault:boolean = false;
    public Seq:number = -1;
    public Selected:boolean = false;
    public Active:boolean = true;
    constructor(values: Object = {}){
        Object.assign(this, values);
    }

}