export class ActionHistory{
    public DateOfChange:Date;
    public ChangeAuthor:string;
    public ChangeDescription:string;
    public PreviousValue:string;
    public CurrentValue:string;
    public ActionID:number = -1;
    public Notes:string ="";
    public ResolvedChange:boolean = false;
    constructor(values: Object = {}){
        Object.assign(this, values);
    }
}