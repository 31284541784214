<div class="h-screen w-screen flex py-8 md:p-0 md:items-center justify-center">
    <div
      class="bg-offwhite-100 rounded-xl center w-11/12 md:w-3/4 shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
    >
      <div class="flex flex-col h-full">
        <div class="flex flex-row h-full">
          <div class="flex flex-col jh-full w-full md:w-3/5 p-4 pr-2">
            <img
              src="/assets/logo.png"
              class="md:hidden max-h-24 mr-4 mt-4 object-scale-down flex justify-self-start"
            />
            
            <div class="flex flex-col justify-center">
                <h1 class="p-4 md:p-8 md:pt-12 pt-16">Trial Signup</h1>
                
                <div class="md:px-8 px-4 pb-4" *ngIf="showPage1">
                    <label class="block pt-4">
                        <span class="">Organisation Name</span>
                        <input type="text" [(ngModel)]="TrialOptions.OrganisationName" class="text-box" />
                    </label>
                    <label class="block pt-4">
                        <span class="">Organisation Email</span>
                        <input type="email" [(ngModel)]="TrialOptions.OrganisationEmail" class="text-box" />
                    </label>
                    <label class="block pt-4">
                        <span class="">Organisation Contact Number</span>
                        <input type="tel" [(ngModel)]="TrialOptions.OrganisationPhone" class="text-box" />
                    </label>
                    <label class="block pt-4">
                        <span class="">Organisation Website</span>
                        <input type="text" [(ngModel)]="TrialOptions.OrganisationWebsite" class="text-box" />
                    </label>
                    <div class="flex justify-end">
                        <button class="btn-primary mt-6" (click)="page2()">Next ></button>
                    </div>     
                </div>
                <div class="md:px-8 px-4 pb-4" *ngIf="showPage2">
                    <label class="block pt-4">
                        <span class="">Contact Name</span>
                        <input type="text" [(ngModel)]="TrialOptions.ContactName" class="text-box" />
                    </label>
                    <label class="block pt-4">
                        <span class="">Contact Email</span>
                        <input type="email" [(ngModel)]="TrialOptions.ContactEmail" class="text-box" />
                    </label>
                    <p>Text about signing up, 14 day trial, cancel anytime. Up to 5 users.</p>
                    
                    <div class="flex justify-between">
                        <button class="btn-secondary mt-6" (click)="page1()">< Back</button>
                        <button class="btn-primary mt-6" (click)="createCheckout()">Next ></button>
                    </div>     
                </div>

            </div>
            
          </div>
          <div
            class="flex-col justify-between w-2/5 p-4 pl-2 pb-0 bg-offwhite-500 rounded-xl hidden md:flex"
          >
            <img
              src="/assets/logo.png"
              class="p-4 mt-4 center max-h-28 object-scale-down"
            />
            <img src="/assets/tabletuser.png" class="object-bottom" />
          </div>
        </div>
      </div>
    </div>
  </div>
  