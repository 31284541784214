<div class="m-4 p-2 border border-grey-900 rounded-md bg-grey-100">
    <h3 class="p-2">Add Audit</h3>
    <div class="p-4 pt-6" *ngIf="showAudit">
        <label class="block">
          <span class="font-bold standardTextSize">Audit</span>
          <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="selectedAudit" (change)="selectedAuditChanged()">
            <option *ngFor="let audit of audits.Audits" [ngValue]="audit" [disabled]="audit.AuditID == -1">
              {{ audit.AuditName }}
            </option>
          </select>
        </label>
    </div>
    <div class="p-4" *ngIf="showLocation">
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAudit()">edit</span> {{selectedAudit.AuditName}}</p>
        <div *ngIf="!topLevel" (click)="resetLocations()">
            <span>Reset Locations</span>
        </div>
        <div *ngFor="let l of selectedLocations" class="flex w-full my-2">
            <span
                class="p-2 md:p-4 flex-grow standardTextSize bg-standardwhite border-spacing-1.5 border-b-grey-500 border-s-2  flex justify-between align-middle hover:hover-for-select"
                [ngClass]="{'selected': l.Location.LocationID == selectedLocation.Location.LocationID}"
                (click)="selectLocation(l)">{{l.Location.LocationName}}</span>
            <div *ngIf="l!.SubLocations.length > 0"
                class=" bg-standardwhite "
                (click)="chooseSublocation(l)">
                <span class="standardTextSize text-offblack hover:hover-for-select border-grey-900 border-spacing-2 border-s h-full px-4 flex items-center">Sub Locations</span>
            </div>
        </div>
    </div>
    <div class="p-4 pt-6" *ngIf="showUsers">
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAudit()">edit</span> {{selectedAudit.AuditName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToLocation()">edit</span> {{selectedLocation.Location.LocationName}}</p>
        <label class="block">
          <span class="font-bold standardTextSize">Auditor</span>
          <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="selectedUser" (change)="selectedAuditorChanged()" >
            <option *ngFor="let user of allUsers" [ngValue]="user" [disabled]="user.UserID == -1"
            [ngClass]="{'font-bold':user.UserTypeID==2}">
              {{ user.DisplayName }}
            </option>
          </select>
        </label>
    </div>
    <div class="p-4 pt-6" *ngIf="showFrequency">
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAudit()">edit</span> {{selectedAudit.AuditName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToLocation()">edit</span> {{selectedLocation.Location.LocationName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAuditor()">edit</span> {{selectedUser.DisplayName}}</p>
        <span class="font-bold standardTextSize">Frequency</span>
          <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="selectedFrequency" (change)="selectedFrequencyChanged()" >
            <option *ngFor="let freq of allFrequencies" [ngValue]="freq" [disabled]="freq.ID == -1">
              {{ freq.Description }}
            </option>
          </select>
    </div>
    <div class="p-4 pt-6" *ngIf="showStart">
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAudit()">edit</span> {{selectedAudit.AuditName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToLocation()">edit</span> {{selectedLocation.Location.LocationName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAuditor()">edit</span> {{selectedUser.DisplayName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToFrequency()">edit</span> {{selectedFrequency.Description}}</p>
        <label>
            <span class="font-bold standardTextSize">Start Date</span>
            <div class="flex gap-2 items-baseline">
              <input [owlDateTime]="dt" class="text-box w-1/5" name="startDate" [(ngModel)]="startDate"  [owlDateTimeTrigger]="dt" [min]="today" />
              <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1"  #dt></owl-date-time>
              <button class="btn-primary w-auto text-sm h-8 leading-3 p-2" (click)="updateStartDate()">Confirm Start Date</button>
            </div>
            
        </label>
        <p class="form-error" *ngIf="showStartDateError">The start date is not valid.</p>
    </div>
    <div class="p-4 pt-6" *ngIf="showEnd">
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAudit()">edit</span> {{selectedAudit.AuditName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToLocation()">edit</span> {{selectedLocation.Location.LocationName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToAuditor()">edit</span> {{selectedUser.DisplayName}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToFrequency()">edit</span> {{selectedFrequency.Description}}</p>
        <p class="pb-2"><span class="text-center material-symbols-outlined text-xl text-green-700 self-center hover:cursor-pointer mr-1 align-sub" (click)="backToStartDate()">edit</span> {{startDate.toDateString()}}</p>
        <span class="font-bold standardTextSize">Enter End Date? </span>
        <div class="pb-2">
            <app-toggle-switch [(checked)]="hasEndDate"></app-toggle-switch>
        </div>
        <label *ngIf="hasEndDate">
            <input [owlDateTime]="dt2" class="text-box w-1/5" name="endDate" [(ngModel)]="endDate"  [owlDateTimeTrigger]="dt2" [min]="startDate" (dateTimeChange)="updateEndDate()" />
            <owl-date-time [pickerType]="'calendar'" [firstDayOfWeek]="1" #dt2></owl-date-time>
        </label>
        <button class="btn-primary mt-4" (click)="createEvent()">Create Scheduled Event</button>
        <p class="form-error" *ngIf="showError">The dates entered are invalid, please check and try again.</p>
    </div>
</div>

