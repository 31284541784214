import { BasicResult } from "../basic-result";
import { Audit } from "./audit";
import { FlattenedQuestionHolder } from "./flattened-question-holder";

export class AuditCollectionResult extends BasicResult{
    public Audits: FlattenedQuestionHolder[];
    
    constructor(values: Object = {}){
        super();
        Object.assign(this, values);     
    }
}