import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getMonth, getDate, getDay } from 'date-fns';
import { ScheduledEvent } from 'src/app/core-module/models/Diary/scheduled-event';

@Component({
  selector: 'app-calendar-cell',
  templateUrl: './calendar-cell.component.html',
  styleUrl: './calendar-cell.component.scss'
})
export class CalendarCellComponent {

  @Input() date:Date = new Date();
  @Input() events:ScheduledEvent[] = [];
  @Input() focusMonth: number = -1;

  @Output() selectedDate: EventEmitter<Date> = new EventEmitter<Date>();

  public isFocus:boolean = false;
  public displayDay: number;
  public dayOfWeek: number;

  isFocussedMonth(){
    if (getMonth(this.date) == this.focusMonth){
      this.isFocus = true;
    }
  }

  ngOnChanges(){
    this.isFocus = false;
    this.isFocussedMonth();
    this.displayDay = getDate(this.date);
    this.dayOfWeek = getDay(this.date);
  }

  dateClicked(){
    this.selectedDate.emit(this.date);
  }

}
