<div class="container">
  <h1 class="lg:p-8 lg:pb-4 p-2">Start Audit</h1>
  <div  class="lg:ml-8 p-2">
    <div class="px-4">
      <label class="block pt-4">
        <h3 class="">Audit</h3>
        <select class="text-box w-full md:max-w-xl mt-2 standardTextSize" [(ngModel)]="selectedAudit">
          <option *ngFor="let audit of a.Audits" [ngValue]="audit">
            {{ audit.AuditName }}
          </option>
        </select>
      </label>
    </div>
    <div class="p-4" *ngIf="
        selectedAudit != null &&
        selectedAudit.AuditInfo.ReportDescription.length != 0
      ">
      <p class="standardTextSize">
        {{ selectedAudit.AuditInfo.ReportDescription }}
      </p>
    </div>
    <div class="p-4">
      <button class="btn-primary-full w-full md:max-w-xl" (click)="startAudit()" [class.disabled]="!canStart"
        [disabled]="!canStart">
        <span class="standardTextSize" *ngIf="canStart">Next ></span>
        <span class="standardTextSize" *ngIf="!canStart">Selecting...</span>
      </button>
    </div>
  </div>
  
</div>