import { Injectable } from '@angular/core';
import { BasicUser } from '../models/basic-user';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }
  sortUsers(users:BasicUser[]) {
      return users.sort((a, b) => { if (a.UserTypeID == b.UserTypeID) { return a.DisplayName.localeCompare(b.DisplayName) } else { return b.UserTypeID - a.UserTypeID } });
    ;
  }




}