import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PointGroup, SignaturePad } from 'angular-signature-pad-v2';
import { AuditTemplateMulti } from 'src/app/core-module/models/Audits/Audit-Templates/audit-template-multi';
import { Question } from 'src/app/core-module/models/Audits/question';
import { QuestionAnswer } from 'src/app/core-module/models/Audits/question-answer';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditQuestionsService } from 'src/app/core-module/services/audits/audit-questions.service';

@Component({
  selector: 'app-question-holder',
  templateUrl: './question-holder.component.html',
  styleUrls: ['./question-holder.component.scss'],
})
export class QuestionHolderComponent {
  @Input() currentQuestion: Question;
  @Input() actionUsers: BasicUser[] = [];
  @Output() currentQuestionChange = new EventEmitter<Question>();

  @Input() visible: boolean;
  @Output() updateLocal = new EventEmitter();

  constructor(private auditQuestionService: AuditQuestionsService) { }
  tempDDL: DDL[] = [];
  seq: number = 1;
  currentSelection: any = null;
  showCanvas: boolean = true;
  showWebcam: boolean = false;
  showAttachments: boolean = false;
  defaultAnswerID: number = -1;
  hasDefault: boolean = false;
  comment: string = '';
  showHelp: boolean = false;
  hasMoreText: boolean = false;

  test() {
    //console.log(this.currentQuestion);
  }
  //Canvas Controls
  ngOnInit() {
    this.resetCanvasSize();

  }

  ngOnChanges() {
    if (this.currentQuestion.CompletionInfo.Answer != null) {
      this.showCanvas = false;
    } else {
      this.showCanvas = true;
    }
    if (this.currentQuestion.QuestionType.ID == 1) {
      this.updateDefaultToCurrent();
    }
  }

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  canvasOptions = {
    minWidth: 1,
    maxWidth: 3,
    backgroundColor: 'rgb(255,255,255)',
    canvasWidth: 250,
    canvasHeight: 150
  };

  resetCanvasSize() {
    this.canvasOptions.canvasWidth = Math.min(innerWidth * 0.5, 800);
    this.canvasOptions.canvasHeight = this.canvasOptions.canvasWidth * 0.7;
  }
  clearCanvas() {
    this.currentQuestion.CompletionInfo.Answer = null;
    this.showCanvas = true;
    //cannot resize correctly as screen is wider than expected
    this.resetCanvasSize();
    if (this.signaturePad != undefined) {
      this.signaturePad.clear();
    }

  }
  saveCanvas() {
    this.currentQuestion.CompletionInfo.Answer = this.signaturePad.toDataURL();
    this.currentQuestion.CompletionInfo.QuestionAnswered = true;
    //this.updateLocal.emit();
    this.currentQuestionChange.emit(this.currentQuestion);
  }
  //End Of Canvas Controls
  updateNAStatus() {
    if (this.currentQuestion.NASelected) {
      this.currentQuestion.CompletionInfo.QuestionAnswered = true;
    }
  }
  updateLocalData() {

    this.updateLocal.emit();
  }

  updateDefaultToCurrent() {
    if (this.currentQuestion.CompletionInfo.QuestionAnswered) {
      return;
    }
    var currentAnswer: QuestionAnswer;
    this.currentQuestion.QuestionAnswers.forEach(e => {
      if (e.IsDefault) {
        e.Selected = true; currentAnswer = e;
        this.currentQuestion.CompletionInfo.Answer = e.QuestionAnswerID
        this.currentQuestion.CompletionInfo.QuestionAnswered = true;
        this.currentQuestionChange.emit(this.currentQuestion);
        this.updateLocalData();
      } else { e.Selected = false }
    });
  }

  currentMultiInput(): AuditTemplateMulti {
    return new AuditTemplateMulti({ 'Values': this.currentSelection, 'List': this.tempDDL })
  }

  showAction() {
    this.currentQuestion.CompletionInfo.NonCompliant = false;
    if (this.currentQuestion.NASelected) {
      return false;
    }
    switch (this.currentQuestion.QuestionType.ID) {
      case 1:
      case 2:
        var localAnswerID: number = 0;
        if (this.currentQuestion.CompletionInfo.Answer == null) {
          return false;
        }
        if (this.currentQuestion.CompletionInfo.QuestionAnswered) {
          localAnswerID = Number(this.currentQuestion.CompletionInfo.Answer);
        } else {
          localAnswerID = this.defaultAnswerID;
        }
        var QuestionAnswer: QuestionAnswer = this.currentQuestion.QuestionAnswers.find((e) => {
          return e.QuestionAnswerID == localAnswerID;
        })!;
        if (
          Number(QuestionAnswer!.ComplianceScore) <=
          Number(this.currentQuestion.ComplianceLimit)
        ) {
          this.currentQuestion.CompletionInfo.NonCompliant = true;
          return true;
        }
        break;
      case 3:
        var localAnswerIDs: number[] = [];
        var totalScore: number = 0;
        if (this.currentQuestion.CompletionInfo.Answer == null) {
          return false;
        }
        if (this.currentQuestion.CompletionInfo.QuestionAnswered) {
          var tempIDs: number[] = this.currentQuestion.CompletionInfo.Answer;
          tempIDs.forEach(e => {
            localAnswerIDs.push(e);
            totalScore += this.currentQuestion.QuestionAnswers.find(f => {
              return f.QuestionAnswerID == e;
            })!.ComplianceScore
           
          });
        } else {
          //localAnswerID = this.defaultAnswerID;
        }

        if (
          Number(totalScore) <=
          Number(this.currentQuestion.ComplianceLimit)
        ) {
          this.currentQuestion.CompletionInfo.NonCompliant = true;
          return true;
        }
        break;
    }
    return false;
  }

  showPhoto() {
    this.showWebcam = !this.showWebcam;
    this.showAttachments = false;
  }
  showAttachment() {
    this.showAttachments = !this.showAttachments;
    this.showWebcam = false;
  }
  changeHelp() {
    this.showHelp = !this.showHelp;
  }
  helpText() {
    if (this.showHelp || this.currentQuestion.HelpText.length <= 70) {
      this.hasMoreText = false;
      return this.currentQuestion.HelpText;
    }
    if (!this.showHelp) {
      this.hasMoreText = true
      return this.currentQuestion.HelpText.substring(0, 50);

    }
    return "";
  }
}
