<div class="h-screen w-screen flex py-8 md:p-0 md:items-center justify-center">
    <div
      class="bg-offwhite-100 rounded-xl center w-11/12 md:w-3/4 shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
    >
      <div class="flex flex-col h-full">
        <div class="flex flex-row h-full">
          <div class="flex flex-col jh-full w-full md:w-3/5 p-4 pr-2">
            <img
              src="/assets/logo.png"
              class="md:hidden max-h-24 mr-4 mt-4 object-scale-down flex justify-self-start"
            />
            
            <div class="flex flex-col justify-center">
              <h1 class="p-4 md:p-8 md:pt-12 pt-16">Password Reset</h1>
              
              <div class="md:px-8 px-4 pt-6">
                <label class="block pt-4">
                  <span class="">New Password</span>
                  <input
                    type="password"
                    [(ngModel)]="newPassword"
                    class="text-box"
                  />
                </label>
                <label class="block pt-4">
                    <span class="">Confirm Password</span>
                    <input
                      type="password"
                      [(ngModel)]="confirmPassword"
                      class="text-box"
                    />
                  </label>
                <div class="pt-10">
                  <button
                      class="btn-primary-full"
                      (click)="resetPassword()"
                      [class.disabled]="newPassword.length == 0"
                      [disabled]="newPassword.length == 0"
                    >
                      <span *ngIf="!resetClicked">Reset Password</span>
                      <span *ngIf="resetClicked">Resetting Password...</span>
                    </button>
                    <span class="pt-4 form-error" *ngIf="resetErrorText.length > 0">{{resetErrorText}}</span>
                    <a (click)="showLogin()" class="text-sm block underline hover:cursor-pointer pt-4">< Back to Login</a>
                </div>
              </div>
 
            </div>
            
          </div>
          <div
            class="flex-col justify-between w-2/5 p-4 pl-2 pb-0 bg-offwhite-500 rounded-xl hidden md:flex"
          >
            <img
              src="/assets/logo.png"
              class="p-4 mt-4 center max-h-28 object-scale-down"
            />
            <img src="/assets/tabletuser.png" class="object-bottom" />
          </div>
        </div>
      </div>
    </div>
  </div>