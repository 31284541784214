import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Audit } from 'src/app/core-module/models/Audits/audit';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-audit-result',
  templateUrl: './audit-result.component.html',
  styleUrl: './audit-result.component.scss'
})
export class AuditResultComponent {
  @Input() audit: Audit;
  @Output() resetNewAction = new EventEmitter<number>();

  constructor(private utilService:UtilService){

  }


  calcPercentage():number{
    return this.utilService.calcPercentage(this.audit.AuditCompletionInfo.Score,this.audit.AuditCompletionInfo.MaxScore);

  }
  formattedSubmissionDate():string{
    return this.utilService.humanDate(this.audit.AuditCompletionInfo.SubmittedDate)
  }
  selectAudit(){
    this.resetNewAction.emit(this.audit.AuditCompletionInfo.AuditResultID);
  }
  overdueAudits():number{
    return this.audit.AuditCompletionInfo.TotalOutstandingActions - this.audit.AuditCompletionInfo.TotalOverdueActions;
  }
}
