import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { Question } from 'src/app/core-module/models/Audits/question';

@Component({
  selector: 'app-question-photo',
  templateUrl: './question-photo.component.html',
  styleUrl: './question-photo.component.scss'
})
export class QuestionPhotoComponent {
  @Input() currentQuestion: Question;
  @Input() showWebcam: boolean;
  @Output() currentQuestionChange = new EventEmitter<Question>();

  private trigger: Subject<void> = new Subject<void>();

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }


  

  takePicture(){
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.currentQuestion.CompletionInfo.Photos.push(webcamImage.imageAsDataUrl);
    this.currentQuestionChange.emit(this.currentQuestion);
  }

  public removePhoto(Photo:string){
    let idx = this.currentQuestion.CompletionInfo.Photos.findIndex(e => e == Photo);
    if (idx != -1){
      this.currentQuestion.CompletionInfo.Photos.splice(idx,1);
      this.currentQuestionChange.emit(this.currentQuestion);
    }
  }


}
