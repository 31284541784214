import { Component } from '@angular/core';
import { tap } from 'rxjs';
import { TrialSignupOptions } from 'src/app/core-module/models/Trial/trial-signup-options';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrl: './trial.component.scss'
})
export class TrialComponent {

  public showPage1:boolean = true;
  public showPage2:boolean = false;
  public TrialOptions:TrialSignupOptions = new TrialSignupOptions();
  
  constructor(private user:UserManagementService, private toast: ToastrService){}

  public page2(){
    //validate
    this.showPage1 = false;
    this.showPage2 = true;
  }

  public page1(){
    this.showPage1 = true;
    this.showPage2 = false;
  }

  createCheckout(){
    //validate
    this.user.createCheckoutSession(this.TrialOptions).pipe(
      tap((res:string) => {
        if (res.length > 0){
          window.location.href = res;
        } else {
          this.toast.addToastr(new Toastr({Type: 3, Message: "An error occurred getting your billing information, please try again later."}));
        }
      })
    ).subscribe();
  }
}
