import { BasicUser } from "../basic-user";
import { UserLocation } from "./user-location";

export class UserLocationsHolder{
    UserLocations:UserLocation[] = [];
    PossibleUsers:BasicUser[] = [];
    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('PossibleUsers')) {
            this.PossibleUsers = [];
            ((values as UserLocationsHolder)['PossibleUsers'] as BasicUser[]).forEach(element => {
                this.PossibleUsers.push(new BasicUser(element));
            });
        }
        if (values.hasOwnProperty('UserLocations')) {
            this.UserLocations = [];            
            ((values as UserLocationsHolder)['UserLocations'] as UserLocation[]).forEach(element => {
                this.UserLocations.push(new UserLocation(element));
            });
        }
    }
}