import { Component, EventEmitter, Input, Output } from '@angular/core';
import { addDays, getDay, getDaysInMonth, getMonth, getYear, isBefore, isEqual, parseISO, subDays } from 'date-fns';
import { tap } from 'rxjs';
import { ScheduledEvent } from 'src/app/core-module/models/Diary/scheduled-event';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { DDL } from 'src/app/core-module/models/ddl';
import { DiaryService } from 'src/app/core-module/services/diary.service';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent {

  public months: DDL[] = [];
  public years: number[] = [];
  public selectedMonth: DDL;
  public selectedYear: number;

  @Input() public allEvents: ScheduledEvent[];
  @Output() FromChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() EventDeleted: EventEmitter<ScheduledEvent> = new EventEmitter<ScheduledEvent>();
  
  public weeksInMonth:number[] = [];
  public daysInWeek:number[] = [1,1,1,1,1,1,1];
  public datesForCells: Date[] = [];
  public eventsForCells: ScheduledEvent[][] = [];

  public selectedDate: Date | null = null;
  public selectedEvents: ScheduledEvent[];


  constructor(public refService: ReferenceService, public DiaryService: DiaryService, public toastrService: ToastrService){ }

  ngOnInit(){
    let currentYear: number = getYear(new Date())
    for(let i = currentYear - 5; i < currentYear + 5; i++){
      this.years.push(i);
    }
    this.refService.getMonths().pipe(
      tap(res => {
        if (res){
          this.months = res;
          this.selectedMonth = this.months[getMonth(new Date())];
          this.selectedYear = currentYear;
          this.updatedDate();
        }
      })
    ).subscribe();
  }

  ngOnChanges(){
    this.allEvents.sort((a,b) => {
      if (isBefore(a.AuditDate,b.AuditDate)) return 0; return 1
    }); 
    this.calculateCalendarLayout();
  }

  updatedDate(){
    this.FromChanged.emit(new Date(this.selectedYear,this.selectedMonth.ID,1));
    this.calculateCalendarLayout();
  }

  calculateCalendarLayout(){
    if (this.selectedMonth == undefined) return;
    let dayOfStart = getDay(new Date(this.selectedYear,this.selectedMonth.ID,1));
    let daysInMonth = getDaysInMonth(new Date(this.selectedYear,this.selectedMonth.ID,1));
    let weeks = Math.ceil((daysInMonth + dayOfStart) / 7);
    this.weeksInMonth = [];
    for(let i = 1; i <= weeks; i++){
      this.weeksInMonth.push(1);
    }
    let firstDate = subDays(new Date(this.selectedYear,this.selectedMonth.ID,1),dayOfStart);
    this.datesForCells = [];
    this.eventsForCells = [];
    for(let i = 0; i < weeks * 7; i++){
      this.datesForCells.push(addDays(firstDate,i));
      this.eventsForCells.push(this.allEvents.filter(ev=> {return isEqual(parseISO(ev.AuditDate.toString()),addDays(firstDate,i))}));
    }
    if (this.selectedDate != undefined){
      this.selectedEvents = this.allEvents.filter(ev => {return isEqual(ev.AuditDate,this.selectedDate!)});  
    }
  }

  selectedChanged(event:any){
    this.selectedDate = event;
    this.selectedEvents = this.allEvents.filter(ev => {return isEqual(ev.AuditDate,this.selectedDate!)});
  }

  deleteEvent(event:ScheduledEvent){
    this.EventDeleted.emit(event);

  }

  deleteEvent2(event: ScheduledEvent){
    
    this.DiaryService.deleteDiaryEvent(event.ScheduledAuditID,event.AuditSeriesID,event.DeleteSeries).pipe(
      tap(res => {
        if (!res.body?.IsError){
          //success
          this.toastrService.addToastr(new Toastr({Message:"Event deleted.",Type:2}));
          this.EventDeleted.emit(event);
        } else {
          //error
          this.toastrService.addToastr(new Toastr({Message:"An error occurred deleting the event. Please try again later.",Type:3}));
          console.error(res.body);
        }
      })
    ).subscribe();
    
  }

}
