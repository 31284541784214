import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { ActionHolder } from 'src/app/core-module/models/Compliance-Actions/action-holder';
import { ActionListRequest } from 'src/app/core-module/models/Compliance-Actions/action-list-request';
import { ActionResult } from 'src/app/core-module/models/Compliance-Actions/action-result';
import { ActionUserRequest } from 'src/app/core-module/models/Compliance-Actions/action-user-request';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { ComplianceActionService } from 'src/app/core-module/services/compliance-action.service';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import { UserService } from 'src/app/core-module/services/user.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent {

  constructor(private userService: AuthService,  private router: Router,  public actionService: ComplianceActionService,private refService:ReferenceService, private auditService:AuditService, private userHelperService:UserService) { }

  includeComplete:boolean = false;

  public showFilters:boolean = true;
  public returnedAudits:AuditCollectionResult = new AuditCollectionResult;
  public auditDDLList:DDL[] = [];
  public selectedAuditDDL: DDL = new DDL();  

  public returnedLocations:AuditLocation[] = [];
  public locations:DDL[] = [];
  public selectedLocationDDL: DDL = new DDL();

  public returnedUsers:BasicUser[] = [];
  public users:DDL[] = [];
  public selectedUserDDL: DDL = new DDL();

  public startDate:Date = new Date(new Date().setMonth(new Date().getMonth() - 3));
  public endDate:Date = new Date();

  private request:ActionListRequest = new ActionListRequest({StartDate:this.startDate, EndDate:this.endDate,AuditID:-1,LocationID:-1,AuditorUserID:-1})


  ngOnInit() {   
    this.getAuditFilter();
    this.getValidLocation();
    this.getAuditorData();
    this.getActionData();
  }

  actionList: ActionResult = new ActionResult();


  getActionData(){
    this.actionService.getCurrentActions(this.request).pipe(
      tap(res => {
        if(res.IsError){
          //Error here
        }else{
          this.actionList = res;
        }
        
      })
    ).subscribe();
  }
  updateRequest(){
    this.request = new ActionListRequest({
      IncludeResolved:this.includeComplete,
      StartDate:this.startDate,
      EndDate:this.endDate,
      AuditID:this.selectedAuditDDL.ID,
      LocationID:this.selectedLocationDDL.ID,
      AuditorUserID:this.selectedUserDDL.ID
    })
  }

  refreshActionData(){
    this.updateRequest();
    this.getActionData();
  }
  refreshSearch(){
    this.updateRequest();
    this.getActionData();
  }
  unhideFilters(){
    this.showFilters = !this.showFilters;
  }
  getValidLocation(){
    this.refService.getFlatLocationsForUser().pipe(
      tap(res =>{
        if(res!=null){
          this.returnedLocations = res
          this.buildLocations();
        }
      })
    ).subscribe();
  }
  buildLocations(){
    this.locations = [];
    this.locations.push(new DDL({ID:-1,Description:"All Locations"}));
    this.returnedLocations.forEach(e=>{
      this.locations.push(new DDL({ID:e.LocationID,Description:e.LocationName}));      
    })
    this.selectedLocationDDL = this.locations[0];
  }
  getAuditFilter(){
    this.auditService.getAudits().pipe(
      tap(res =>{
        if(res!=null){
          this.returnedAudits = res
          this.buildAuditFilter();
        }
      })
    ).subscribe();
  }
  buildAuditFilter(){
    this.auditDDLList = [];
   
    this.returnedAudits.Audits.forEach(e=>{
      this.auditDDLList.push(new DDL({ID:e.AuditID, Description:e.AuditName}));
    })
    this.auditDDLList = this.auditDDLList.sort((a, b) => {
      if (a.Description > b.Description) {
        return 1;
      }

      if (a.Description < b.Description) {
        return -1;
      }

      return 0;
    })
    this.auditDDLList.unshift(new DDL({ID:-1,Description:'All Audits'}));
    this.selectedAuditDDL = this.auditDDLList[0];
  }
  getAuditorData(){
    this.returnedUsers = [];
    var AUR = new ActionUserRequest();
    this.actionService.getActionUsers(AUR).pipe(
      tap(res => {
        this.returnedUsers = res;
        this.returnedUsers = this.userHelperService.sortUsers(this.returnedUsers);
        this.buildAuditorList();
      })
    ).subscribe();
  }
  buildAuditorList(){
    this.users = [];
    this.users.push(new DDL({ID:-1,Description:'All Action Owners'}));
    this.returnedUsers.forEach(e=>{
      var bold = false;
      if(e.UserTypeID == 2){
        bold = true;
      }
      this.users.push(new DDL({ID:e.UserID, Description:e.DisplayName, Bold:bold}));
    })
    this.selectedUserDDL = this.users[0];
  }
}
