import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditHolder } from '../models/Audits/audit-holder';
import { Observable,map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActionHolder } from '../models/Compliance-Actions/action-holder';
import { BasicResult } from '../models/basic-result';
import { ActionResponse } from '../models/Compliance-Actions/action-response';
import { Action } from '../models/Compliance-Actions/action';
import { BasicUser } from '../models/basic-user';
import { ObservableReference } from '../models/Utils/observable-reference';
import { ActionUserRequest } from '../models/Compliance-Actions/action-user-request';
import { ActionHistory } from '../models/Audits/action-history';
import { ComplianceAction } from '../models/Audits/compliance-action';
import { ActionListRequest } from '../models/Compliance-Actions/action-list-request';
import { ActionResult } from '../models/Compliance-Actions/action-result';

@Injectable({
  providedIn: 'root'
})
export class ComplianceActionService {

  constructor(private httpClient: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: "response" as "response",
    responseType: "json" as const,
    withCredentials: true
  };
  
  getCurrentActions(request:ActionListRequest): Observable<ActionResult> {
    return this.httpClient.post<ActionResult>(`${environment.apiUrl}actions/actionlist`,request, this.httpOptions).pipe(
      map(res => {
        return new ActionResult(res.body!);
      })
    );
  }

  updateAction(actionResponse: ActionResponse): Observable<BasicResult> {
    return this.httpClient.post<ActionResponse>(`${environment.apiUrl}actions/updateaction`, new ActionResponse(actionResponse), this.httpOptions).pipe(
      map(res => {
        return new BasicResult(res.body!);
      })
    );
  }
  
  modifyAction(modifiedAction: Action): Observable<BasicResult>{
    return this.httpClient.post<Action>(`${environment.apiUrl}actions/modifyaction`, new Action(modifiedAction), this.httpOptions).pipe(
      map(res => {
        return new BasicResult(res.body!);
      })
    );
  }
  saveNewAction(newAction:ComplianceAction):Observable<BasicResult>{
     return this.httpClient.post<ComplianceAction>(`${environment.apiUrl}actions/newaction`, new Action(newAction), this.httpOptions).pipe(
      map(res => {
        return new BasicResult(res.body!);
      })
    );
  }



  getActionUsers(AUR: ActionUserRequest): Observable<BasicUser[]>{
    return this.httpClient.post<BasicUser[]>(`${environment.apiUrl}actions/actionusers`, AUR, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  getActionHistory(auditID:number):Observable<ActionHistory[]>{
    return this.httpClient.post<ActionHistory[]>(`${environment.apiUrl}actions/getactionhistory`, auditID, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  getActionByID(actionID:number):Observable<Action>{
    return this.httpClient.post<Action>(`${environment.apiUrl}actions/getactiondetail`, actionID, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }


}
