import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { MainGuard } from '../core-module/guards/main.guard';

import { PUBLIC_ROUTES } from '../public/public.routes';
import { SECURE_ROUTES } from '../secure/secure.routes';
import { PublicComponent } from '../shared-module/layout/public/public.component';
import { SecureComponent } from '../shared-module/layout/secure/secure.component';
import { authGuard } from '../core-module/guards/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', component: PublicComponent, children: PUBLIC_ROUTES },
  { path: '', component: SecureComponent, canActivate: [authGuard()], children: SECURE_ROUTES },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
