import { Component, Input } from '@angular/core';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrl: './toastr.component.scss'
})
export class ToastrComponent {
  @Input() toastr:Toastr = new Toastr;
}
