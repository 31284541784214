import { BehaviorSubject, Observable } from "rxjs";

export class ObservableReference{
    ID:string;
    Behavior:BehaviorSubject<any>;
    Obs:Observable<any>
    constructor(id:string,behavior:BehaviorSubject<any>){
        this.ID=id;
        this.Behavior = behavior;
        this.Obs = this.Behavior.asObservable();
    }
}