import { Component } from '@angular/core';
import { isEqual } from 'date-fns';
import { tap } from 'rxjs';
import { ScheduledEvent } from 'src/app/core-module/models/Diary/scheduled-event';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { DiaryService } from 'src/app/core-module/services/diary.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrl: './diary.component.scss'
})
export class DiaryComponent {

  public showAddAudit: boolean = false;
  public FromDate: Date;

  public allEvents: ScheduledEvent[] = [];

  

  constructor(public diaryService: DiaryService, public toastrService: ToastrService){ }

  ngOnInit(){
    //this.updateEvents();
  }

  showAdd(){
    this.showAddAudit = !this.showAddAudit;
  }

  updateEvents(){
    this.showAddAudit = false;
    this.diaryService.getEvents(this.FromDate).pipe(
      tap(res => {
        if (res.body){
          this.allEvents = res.body!;
        }
      })
    ).subscribe();
  }

  updateDate(event:any){
    this.FromDate = event;
    this.updateEvents();
  }

}
