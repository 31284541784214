import { FeaturesList } from "./features-list";

export class AuditLocation {
    LocationID: number = -1;
    LocationName: string = "";
    LocationLevelID: number = -1;
    Seq: number = -1;
    ParentLocationID: number = -1;
    LocationLevelSeq: number = -1;
    Active: boolean = true;
    Features: FeaturesList = new FeaturesList();
    SubLocationFeatures:FeaturesList = new FeaturesList();
    HasPermission: boolean = false;

    constructor(values: Object = {}) {
        Object.assign(this, values);
        if(values.hasOwnProperty('Features')){
            this.Features = new FeaturesList((values as AuditLocation)['Features'] as FeaturesList);
        }
        if(values.hasOwnProperty('SubLocationFeatures')){
            this.SubLocationFeatures = new FeaturesList((values as AuditLocation)['SubLocationFeatures'] as FeaturesList);
        }else{
            this.SubLocationFeatures = new FeaturesList();
        }
    }
    /*constructor(params: Location = {} as Location){
        const {
            ID = -1,
            Name = "",
            Locations = []
        } = params;


        this.ID = ID;
        this.Name = Name;
        this.Locations = Locations;
    }*/
}