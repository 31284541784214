<div *ngIf="showAttachments" class="mt-4 border border-grey-900 rounded-md bg-standardwhite p-4">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

    

    <div class="pt-2" *ngIf="currentQuestion.CompletionInfo.Attachments.length > 0">
        <h3>Attachments</h3>
        <table class="mt-4 w-full table-fixed bg-standardwhite border border-green-700 p-2">
            <thead>
                <tr class="border-b border-green-700">
                    <th class="w-3/4 md:w-1/2 text-left px-2">Filename</th>
                    <th class="hidden lg:table-cell text-right">Size</th>
                    <!--<th class="hidden md:table-cell">Download</th>-->
                    <th class="p-2 text-right">Remove</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let a of currentQuestion.CompletionInfo.Attachments">
                    <td class="p-2 w-3/4 md:w-1/2">{{a.FileName}}</td>
                    <td class="p-2 text-right hidden lg:table-cell">{{humanFileSize(a.Length)}}</td>
                    <!--<td class="p-2 text-center hidden md:table-cell"><button class="btn-primary" (click)="fetchAttachment(a)"><span class="material-symbols-outlined text-xl mr-1 align-bottom text-standardwhite self-center">download</span></button></td>-->
                    <td class="p-2 text-right"><button class="btn-negative"><span (click)="removeAttachment(a)" class="material-symbols-outlined text-xl mr-1 align-bottom text-standardwhite self-center">delete</span></button></td>
                </tr>
            </tbody>
        </table>
        
    </div>

    <button class="btn-primary mt-4" (click)="fileUpload.click()"><span class="material-symbols-outlined text-xl mr-1 align-bottom text-standardwhite self-center">attach_file</span> <span class="inline-block align-text-bottom">Add new Attachment</span> </button>
    
    <div class="progress-bar mt-2" *ngIf="uploadProgress!=null"> 
        <div class="progress-bar-fill" [ngStyle]="{'width.%': uploadProgress}"></div>
    </div>
    
    
</div>

