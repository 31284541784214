export class TrialSignupOptions {
    public OrganisationName:string = "";
    public OrganisationEmail:string = "";
    public OrganisationPhone:string = "";
    public OrganisationWebsite:string = "";
    public ContactName: string = "";
    public ContactEmail: string = "";

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}