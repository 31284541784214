<div class="flex flex-col my-3 border-solid lg:w-3/5 border-grey-100 border-2 bg-grey-50 rounded-lg px-4 py-2" [ngClass]="{'py-4':optionOpen}">
    <div class="flex" (click)="switchOpen()">
        <h3 class=" text-3xl">{{optionsHolder.SectionName}} </h3>
        <span *ngIf="!optionOpen" class="text-4xl align-baseline material-symbols-outlined text-green-700 hover:cursor-pointer ">chevron_right</span>
        <span *ngIf="optionOpen" class="text-4xl align-baseline material-symbols-outlined text-green-700 hover:cursor-pointer ">keyboard_arrow_down</span>
    </div>
   <div *ngIf="optionOpen" class="mx-3 ">
     <div *ngFor="let a of optionsHolder.Options; let even = even" class="mt-1" (click)="goToPage(a.URL)"  >
        <p class="text-lg hover:cursor-pointer hover:underline">{{a.MenuName}} ></p>
    </div>
   </div>
   
</div>