<h2 class="pt-12">Audit Information</h2>
    <div class="pt-8">
        <label>
            <span class="text-sm">Name</span>
            <input [(ngModel)]="audit.AuditName" (ngModelChange)="auditChanged()" type="text" class="text-box"/>
        </label>
    </div>
    <div class="pt-8">
        <label>
            <span class="text-sm">Type</span>
            <select [(ngModel)]="audit.AuditInfo.AuditType" (ngModelChange)="auditChanged()" [compareWith]="auditTypeComparison" class="text-box">
                <option [disabled]="!t.Active"  *ngFor="let t of _auditTypes" [ngValue]="t">{{t.Description}}</option>
            </select> 
        </label>
    </div>
    <div class="pt-8">
        <label>
            <span class="text-sm">Code</span>
            <input [(ngModel)]="audit.AuditInfo.Code" (ngModelChange)="auditChanged()" type="text" class="text-box"/>
        </label>
    </div>
    
    <div class="pt-8">
        <label>
            <span class="text-sm">Description</span>
            <textarea [(ngModel)]="audit.AuditInfo.ReportDescription" (ngModelChange)="auditChanged()" type="text" class="text-box" rows="3"></textarea>
        </label>
    </div>
    <div class="pt-8">
        <label>
            <span class="text-sm">Revision Date</span>
            <input [(ngModel)]="audit.AuditInfo.RevisionDate" (ngModelChange)="auditChanged()" type="text" class="text-box"/>
        </label>
    </div>
    <div class="pt-8">
        <label>
            <span class="text-sm">Revision Notes</span>
            <textarea [(ngModel)]="audit.AuditInfo.RevisionNotes" (ngModelChange)="auditChanged()" type="text" class="text-box" rows="3"></textarea>
        </label>
    </div>
