import { AuditLocation } from "../../Locations/audit-location";
import { Question } from "../question";
import { QuestionAttachment } from "../question-attachment";
import { AuditResultQuestion } from "./audit-result-question";
import { BasicAction } from "./basic-action";

export class AuditResultDetail{
    public AuditID:number = -1;;
    public AuditResultID:number = -1;
    public AuditName:string = "";
    public UserCreatedID:number = -1;
    public UserCreatedName:string = "";
    public Location:AuditLocation = new AuditLocation();
    public SubmittedDate:Date = new Date();
    public Score:number = -1;
    public MaxScore:number = -1;
    public MaximumAvailableQuestions:number = -1;
    public TotalQuestionsCompleted:number = -1;
    public TotalOutstandingActions:number = -1;
    public TotalOverdueActions:number = -1;
    public Questions:AuditResultQuestion[] = [];

    constructor(values: Object = {}){
        Object.assign(this, values);   
        if(values.hasOwnProperty('Actions')){
            this.Questions = [];
            ((values as AuditResultDetail)['Questions'] as AuditResultQuestion[]).forEach(element => {
                this.Questions.push(new AuditResultQuestion(element));
            });
        }
        if(values.hasOwnProperty('Location')){
            this.Location = new AuditLocation((values as AuditResultDetail)['Location'] as AuditLocation) 
         }           
        
    }

}