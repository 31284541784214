export class DiaryEvent{
    AuditSeriesID: number = -1;
    AuditID: number = -1;
    LocationID: number = -1;
    ScheduledUserID: number = -1;
    FrequencyID: number = -1;
    StartDate: Date;
    EndDate: Date;
    HasEndDate: boolean = true;
    CreatedUserID: number = -1;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}