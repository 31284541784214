import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Question } from 'src/app/core-module/models/Audits/question';

@Component({
  selector: 'app-text-entry',
  templateUrl: './text-entry.component.html',
  styleUrls: ['./text-entry.component.scss'],
})
export class TextEntryComponent {
  public currentValue: string = '';
  @Input() currentQuestion:Question;
  @Output() currentQuestionChange = new EventEmitter<Question>();
  
  ngOnChanges(){
    this.validateIncomingValue(this.currentQuestion.CompletionInfo.Answer);
  }

  validateIncomingValue(value: string) {
    if (value == null) {
      this.currentValue = '';
    } else {
      this.currentValue = <string>value;
    }
  }
  updateCurrentAnswer() {
    this.currentQuestion.CompletionInfo.Answer = this.currentValue;
    this.currentQuestion.CompletionInfo.QuestionAnswered = this.currentValue.length>0;
    this.currentQuestionChange.emit(this.currentQuestion);
  }
}
