import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { AuditService } from 'src/app/core-module/services/audit.service';

@Component({
  selector: 'app-select-audit-edit',
  templateUrl: './select-audit-edit.component.html',
  styleUrls: ['./select-audit-edit.component.scss']
})
export class SelectAuditEditComponent {

  public audits:AuditCollectionResult;

  constructor(private auditService:AuditService, private router:Router){
    
  }

  ngOnInit(){
    this.auditService.getAudits().pipe(
      tap(res => {
        this.audits = res;
      })
    ).subscribe();
  }

  selectAudit(audit:FlattenedQuestionHolder){
    this.router.navigateByUrl('settings/manage/edit/audit',{state:{'Audit': audit}});
  }

  goBack(){
    this.router.navigateByUrl("/settings");
  }
}
