import { LocationLevel } from "./location-level";
import { AuditLocation } from "./audit-location";

export class LocationHolder {
    public Location: AuditLocation = new AuditLocation();
    public SubLocations: LocationHolder[] = [];
    public Level: LocationLevel = new LocationLevel();
    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values.hasOwnProperty('SubLocations')) {
            this.SubLocations = [];
            ((values as LocationHolder)['SubLocations'] as LocationHolder[]).forEach(element => {
                this.SubLocations.push(new LocationHolder(element));
            });
        }
        if (values.hasOwnProperty('Location')) {
            this.Location = new AuditLocation((values as LocationHolder)['Location'] as AuditLocation);
        }
        if (values.hasOwnProperty('Level')) {
            this.Level = new LocationLevel((values as LocationHolder)['Level'] as LocationLevel);
        }
    }
}