
import { BasicUser } from "../basic-user";
import { AuditLocation } from "../Locations/audit-location";

export class UserLocation extends AuditLocation{

    Users:BasicUser[];
 
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
        if (values.hasOwnProperty('Users')) {
            this.Users = [];
            ((values as UserLocation)['Users'] as BasicUser[]).forEach(element => {
                this.Users.push(new BasicUser(element));
            });
        }
    }
}