import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserSettings } from '../interfaces/user-settings';
import { LocalStorageRefService } from './local-storage-ref.service';
import { UserLocationSave } from '../models/user-location-save';
import { LocationResult } from '../models/Locations/location-result';
import { LocalStorageObservables } from '../models/Utils/local-storage-observables';
import { LocalStorageReferences } from '../models/Utils/local-storage-references';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _localStorage: Storage;

  public observables: LocalStorageObservables = new LocalStorageObservables();

  constructor(private _localStorageRefService: LocalStorageRefService) {
    this._localStorage = _localStorageRefService.localStorage;
  }
  setData(type: number, data: any) {
    var temp = LocalStorageReferences.find((e) => e.ID == type)!;
    var obsRef = this.observables.localRef.find((e) => {
      return e.ID == temp.keyName;
    })!;
    this._localStorage.setItem(temp.keyName,JSON.stringify(data))
    obsRef.Behavior.next(data);
  }
  loadData(type: number) {
    var temp = LocalStorageReferences.find((e) => e.ID == type)!;
    var obsRef = this.observables.localRef.find((e) => {
      return e.ID == temp.keyName;
    })!;
    this.retrieveData(temp.keyName, temp.keyType, obsRef.Behavior);
    //this.retrieveData('locations',LocationResult,this.observables._locations.propname!);
  }
  retrieveData<T extends any>(
    key: string,
    keyType: T,
    data: BehaviorSubject<any>
  ) {
    var rawValue = this._localStorage.getItem(key);
    if (rawValue != null) {
      data.next(<typeof keyType>JSON.parse(rawValue));
    }
  }
  updateData(type: number, data: any) {
    this.clearData(type);
    this.setData(type, data);   
  }

  clearData(type: number) {
    var temp = LocalStorageReferences.find((e) => e.ID == type)!;
    var obsRef = this.observables.localRef.find((e) => {
      return e.ID == temp.keyName;
    })!;
    this._localStorage.removeItem(temp.keyName);
    //obsRef.Behavior.next(null as any);
  }
  clearAllInfo() {
    this._localStorage.clear();
    this.observables.localRef.forEach(e=>{
      //e.Behavior.next(null as any);
    });
  }
}
