import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, catchError, tap } from 'rxjs';
import { PasswordValidResult } from 'src/app/core-module/interfaces/password-valid-result';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {

  public token: string = '';

  public resetClicked: boolean = false;
  public newPassword: string = '';
  public confirmPassword: string = '';
  public resetErrorText: string = '';

  constructor(private route: ActivatedRoute, private router: Router, public auth: AuthService, public toastrs:ToastrService) { }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('token') == null) {
      this.router.navigate(["login"]);
    } else {
      this.token = this.route.snapshot.queryParamMap.get('token')!;
    }
  }

  public resetPassword() {
    this.resetErrorText = "";
    let passwordResult: PasswordValidResult = this.auth.validPassword(this.newPassword, this.confirmPassword);
    if (!passwordResult.Valid) {
      if (passwordResult.NewPasswordErrorMessage != '') {
        this.resetErrorText = passwordResult.NewPasswordErrorMessage;
      } else {
        this.resetErrorText = passwordResult.ConfirmPasswordErrorMessage;
      }
      return;
    }

    this.auth.resetPasswordWithToken(this.token, this.newPassword).pipe(
      tap(res => {
        if (!res.body!.IsError){
            this.toastrs.addToastr(new Toastr({Message: 'Password Reset Successfully!', Type: 2}));
            this.newPassword = '';
            this.confirmPassword = '';
            this.showLogin();
          } else {
            this.resetErrorText = res.body?.ErrorMessage!;
          }
          this.resetClicked = false;
      }),
      catchError((err,n) => {
        this.resetErrorText = "An error occured resetting you password, please try again later.";
        this.resetClicked = false;
        return EMPTY;
      })
    ).subscribe();
  }

  public showLogin(){
    this.router.navigate(['login']);
  }

}
