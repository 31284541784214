<div class="m-0 md:ml-8 mt-2 md:mt-0" >
  <label>
    Date:
    <input
      [owlDateTime]="dt2"
      [(ngModel)]="selectedCalendarDate"
      (dateTimeChange)="updateCurrentDate()"
      class="text-box"
      [owlDateTimeTrigger]="dt2"
      [name]="this.currentQuestion.GUID + 'date'"
    />
    <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
  </label>
</div>
<div class="m-0 md:ml-8 mt-2 md:mt-0" >
  <label>
    Time:
    <input 
      [owlDateTime]="dt3"
      [(ngModel)]="selectedTime"
      class="text-box"
      (dateTimeChange)="updateCurrentDate()"
      [owlDateTimeTrigger]="dt3"
      [name]="currentQuestion.GUID + 'time'"
    />
    <owl-date-time [pickerType]="'timer'" #dt3></owl-date-time>
  </label>
</div>