import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuOption } from 'src/app/core-module/models/Utils/menu-option';
import { MenuOptionHolder } from 'src/app/core-module/models/Utils/menu-option-holder';

@Component({
  selector: 'app-menu-holder',
  templateUrl: './menu-holder.component.html',
  styleUrl: './menu-holder.component.scss'
})
export class MenuHolderComponent {
  
  @Input() optionsHolder:MenuOptionHolder;
  public optionOpen:boolean = false;

  constructor(private router:Router){

  }



  goToPage(url:string){
    this.router.navigateByUrl(url)
  }

  switchOpen(){
    this.optionOpen = !this.optionOpen;
  }
}
